/* Blog.css */

html{
  overflow-x: hidden;
  /* overflow-y: hidden; */
}
.blog-container {
  max-width: fit-content;
  margin: 0 auto;
  padding: 20px;
}
.Imgblog{
  width: 100%;
  height: 100vh;
  object-fit: cover;
  
}
 
.img-blogs {
  width: 100%; /* Make the image fill its container */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.3s ease-in-out; /* Smooth transition effect */
}

.img-blogs:hover {
  transform: scale(1.1); /* Scale the image by 1.1 on hover */
}


 
.blogtitle {
  font-family: 'Oswald', sans-serif;
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 50px;
}
 
.containerblog {
  display: flex;
  flex-wrap: wrap;
}
 
.cardblog {
  width: calc(100% / 3);
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
 
.img-blogs {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
 
.card-content-blogblog {
  padding: 20px;
}
 
.card-content-blogblog h2 {
  font-family: system-ui;
  font-size: 24px;
  margin-bottom: 10px;
}
 
.card-content-blogblog p {
  font-size: 15px;
  color: #555;
  margin-bottom: 10px;
}





.popular-cardblog {
  display: flex;
  margin-bottom: 20px;
}

.popular-img-blogs {
  flex: 0 0 auto;
  width: 45%; /* Adjust image width to fit container */
  height: auto; /* Adjust height to maintain aspect ratio */
 margin-left: 9px;
 margin-right: -20px;
  transition: transform 0.3s ease-in-out;
}

.popular-img-blogs:hover {
  transform: scale(1.1);
}

.blogtitle {
  font-size: 36px;
  margin-top: 70px;
  margin-bottom: 15px;
}

.popular-card-content-blogblog h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  margin-bottom: 10px;
  padding-left: 40px;
}

.popular-card-content-blogblog p {
  font-size: 15px;
  margin-bottom: 10px;
}

/* Media Queries for Responsive Design */

@media only screen and (max-width: 768px) {
  .containerblog {
    margin-left: 5%;
    margin-right: 5%;
  }
  .popular-img-blogs {
    width: 100%; /* Adjust image width to fit container */
    height: auto; /* Adjust height to maintain aspect ratio */
  }

  .cardblog {
    width: calc(100% / 2); /* Adjust to 2 columns for medium screens */
  }
}

@media only screen and (max-width: 500px) {
  .cardblog {
    width: 100%; /* Adjust to full width for small screens */
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .card-content-blogblog h2 {
    font-size: 20px; /* Decrease font size further for even smaller screens */
  }

  .popular-img-blogs {
    width: 100%; /* Adjust image width to fit container */
    height: auto; /* Adjust height to maintain aspect ratio */
  }
}
.Loadmore{
  width: 180px;
  height: 50px;
 
  font-size: 15px;
  background: linear-gradient(to right, rgb(0, 0, 0) 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.4s ease-in-out;
 
  border: 2px solid rgb(20, 20, 20);
 
  font-family: system-ui;
}
.Loadmore:hover{
  background-position: left bottom;
  color: rgb(255, 255, 255);
  border: #fcfafa;

}
.Loadmore:hover {
  /* Apply a linear gradient background color */
  background: linear-gradient(to right, black);
  /* Set the text color to white */
  
}

/* Define the animation for the background color transition */
@keyframes gradientAnimation {
  from {
      background: linear-gradient(to right, black);
  }
  to {
      background: linear-gradient(to right, black);
  }
}

/* Apply the animation when the button is hovered */
.Loadmore:hover {
  /* Use the keyframes for the animation */
  animation: gradientAnimation 0.5s forwards;
}
.btn-group {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  margin-left: auto; /* Automatically adjusts margin from left */
  margin-right: auto; /* Automatically adjusts margin from right */
  max-width: 80%; /* Adjust the maximum width of the btn-group */
  margin-bottom: 20px !important;
  margin-top: 0 !important;
}

.btn-group .btn {
  /* Add any additional styling for the buttons */
  font-size: 18px !important;
  width: auto !important;
}
@media screen and (min-width: 500px)and (max-width: 768px) {
  .btn-group .btn {
    font-size: 10px !important;
  }

  .btn-group {
   
  }
      
}

.author-info {
  display: contents; /* Use flexbox to align items horizontally */
  align-items: center; /* Align items vertically centered */
  margin-top: 8px; /* Adjust top margin as needed */
  justify-content: space-between;
}

.author-info svg {
  /* Style for the author icon (assuming it's an SVG) */
  width: 20px; /* Adjust icon size */
  height: 20px;
  margin-right: 4px; /* Add right margin for spacing */
}



