
  #termsedit1s{
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    margin-left: 5%;
    margin-top: 25px;
  }
  .termsheading{
    font-size: 2.2rem;
    line-height: 3.5rem;
    font-weight: 700;
    font-family: system-ui;
    padding-top: 30px;
    
  }
  
 
 .termsheadings{
  font-size: 1.5rem;
  line-height: 3.5rem;
  font-weight: 700;
  font-family: system-ui;
  margin-top: 20px;
}
.termsubpoint{
  font-size: 1.1rem;
  font-family: system-ui;
  font-weight: 450;
  margin-top: 10px;
  padding-bottom: 30px;
}
#terms-list {
  list-style-type: none;
  padding: 0;
  font-size: 1.1rem;
  
}

#terms-list li::before {
  content: "\2022"; /* Bullet character */
  color: #1d1d1d; /* Bullet color */
  font-size: 1.5rem; /* Bullet size */
  margin-right: 0.5em;
}
/* terms.css */

/* Add your existing CSS styles here */

/* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .term{
    text-align: left;
  }
  #termsedit1s {
    font-size: 30px; /* Decrease font size for smaller screens */
    margin-left: 2%; /* Adjust margin for smaller screens */
    margin-top: 15px; /* Adjust margin for smaller screens */
  }

  .termsheading {
    font-size: 1.8rem; /* Decrease font size for smaller screens */
    line-height: 2.5rem; /* Decrease line height for smaller screens */
    text-align: left; /* Align text to the left for smaller screens */
  }

  .termsheadings {
    padding-bottom: 30px;
    font-size: 1rem; /* Decrease font size for smaller screens */
    margin-top: 15px; /* Adjust margin for smaller screens */
    text-align: left; /* Align text to the left for smaller screens */
  }

  .termsubpoint {
    font-size: 1rem; /* Decrease font size for smaller screens */
    margin-top: 5px; /* Adjust margin for smaller screens */
    text-align: left; /* Align text to the left for smaller screens */
  }

  #terms-list {
    font-size: 1rem; /* Decrease font size for smaller screens */
    text-align: left; /* Align text to the left for smaller screens */
  }

  #terms-list li::before {
    font-size: 1rem; /* Decrease bullet size for smaller screens */
  }
}
