.section5jsxcard {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin: 0 auto 3rem auto;
}

.section5jsxcard-3 {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 0 auto 3rem auto;
}

.section5jsxcard-2 {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 0 auto 3rem auto;
}

.card {
  width: 100%;
  min-height: 200px;
  background: #ffffff;
  border-radius: 16px;
  padding: 1.75rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.03);
  transition: all 0.25s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.card::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(145deg, rgba(139, 69, 19, 0.03), rgba(210, 105, 30, 0.03));
  border-radius: inherit;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.card:hover {
  transform: translateY(-4px);
  border-color: rgba(139, 69, 19, 0.15);
  box-shadow: 0 8px 24px rgba(139, 69, 19, 0.08);
}

.card:hover::after {
  opacity: 1;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.casestudyjsx-h2 {
  font-size: 1.4rem;
  font-weight: 600;
  color: #2d2d2d;
  margin: 0;
  transition: color 0.25s ease;
  line-height: 1.4;
}

.card:hover .casestudyjsx-h2 {
  color: #8B4513;
}

.casestudyjsx-p {
  font-size: 0.95rem;
  line-height: 1.6;
  color: #555;
  margin: 0;
}

.overlay-content {
  margin-top: auto;
}

.overlay-content a {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem 1.25rem;
  background: transparent;
  border: 1.5px solid #8B4513;
  border-radius: 8px;
  color: #8B4513;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.25s ease;
  font-size: 0.9rem;
  white-space: nowrap;
}

.overlay-content a:hover {
  background: #8B4513;
  color: white;
  transform: translateY(-2px);
}

.overlay-content a svg {
  width: 16px;
  height: 16px;
  transition: transform 0.25s ease;
}

.overlay-content a:hover svg {
  transform: translateX(3px);
}

@media only screen and (max-width: 550px) {
  .section5jsxcard,
  .section5jsxcard-3,
  .section5jsxcard-2 {
    grid-template-columns: 1fr;
    width: 95%;
    gap: 1.5rem;
    margin: 0 auto 2rem auto;
  }
  
  .card {
    padding: 1.5rem;
    min-height: 180px;
  }
  
  .casestudyjsx-h2 {
    font-size: 1.25rem;
  }
  
  .overlay-content a {
    width: 100%;
    justify-content: center;
  }
}

@media only screen and (min-width: 551px) and (max-width: 1000px) {
  .section5jsxcard,
  .section5jsxcard-3 {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.75rem;
  }
}
