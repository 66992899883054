.hero-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
}

.hero-modal-overlay.active {
  opacity: 1;
  visibility: visible;
}

.hero-modal {
  background: linear-gradient(145deg, #ffffff, #f5f5f5);
  padding: 40px;
  border-radius: 20px;
  width: 95%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  transform: translateY(30px) scale(0.9);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow: 
    0 20px 50px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

.hero-modal-overlay.active .hero-modal {
  transform: translateY(0) scale(1);
  opacity: 1;
}

.hero-modal::-webkit-scrollbar {
  width: 8px;
}

.hero-modal::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.hero-modal::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.hero-modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  font-size: 24px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  color: #333;
  z-index: 2;
}

.hero-modal-close:hover {
  background-color: rgba(139, 69, 19, 0.2);
  transform: rotate(90deg);
  color: #8B4513;
}

.hero-modal-title {
  font-size: 38px;
  font-weight: 800;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  background: linear-gradient(135deg, #1a1a1a 0%, #333 50%, #666 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-bottom: 20px;
  animation: titleFade 0.6s ease-out forwards;
}

.hero-modal-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #8B4513, #A0522D);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(139, 69, 19, 0.2);
}

.hero-modal-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
  margin-top: 30px;
  padding: 10px;
}

.hero-modal-link {
  background: #ffffff;
  padding: 30px;
  border-radius: 16px;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: #2D2D2D;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  min-height: 100px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.hero-modal-link::after {
  content: '→';
  font-size: 24px;
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.3s ease-in-out;
  color: #8B4513;
}

.hero-modal-link:hover {
  transform: translateY(-5px);
  background: #ffffff;
  border-color: rgba(139, 69, 19, 0.2);
  box-shadow: 0 15px 30px rgba(139, 69, 19, 0.1);
  color: #8B4513;
}

.hero-modal-link:hover::after {
  opacity: 1;
  transform: translateX(0);
}

/* Remove the previous ::before gradient line */
.hero-modal-link::before {
  display: none;
}

@keyframes titleFade {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .hero-modal {
    padding: 30px;
  }

  .hero-modal-title {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .hero-modal-links {
    grid-template-columns: 1fr;
  }

  .hero-modal-link {
    padding: 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .hero-modal {
    padding: 20px;
  }

  .hero-modal-title {
    font-size: 24px;
    margin-bottom: 25px;
  }

  .hero-modal-link {
    padding: 15px;
    font-size: 15px;
  }
}