.about {
  font-size: 3.5rem;
  margin-left: 7%;
  font-family: 'Oswald', sans-serif;
}

.our-teamx {
  padding-bottom: 0px;
  margin-left: 10%;
}

.social-link {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b6b1b1;
  border-radius: 50%;
  transition: all 0.3s;
  font-size: 0.9rem;
}

.social-link:hover,
.social-link:focus {
  background: #ddd;
  text-decoration: none;
  color: #555;
}

.about-header {
  background-color: #f8f9fa;
  padding: 100px 0;
}



.about-team {
  padding: 100px 0;
}

.aboutheadings {
  margin-left: 12%;
  font-family: Oswald, sans-serif;
  font-size: 40px;
  font-weight: 500;
}

.text-muted {
  margin-left: 12%;
  font-family: system-ui;
  padding-top: 20px;
  font-family: system-ui;
    font-size: 24.4px;
    font-weight: 400;
    line-height: 30px;
}
.centered-modal {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.container{
  max-width: 80% !important;
}


@media (max-width: 768px) { 
  .about {
    padding-top: 40px;
    text-align: left;
  }
  .img-fluid {
    margin-top: -20px;
  }
  .img-fluids {
    margin-top: 70px;
  }
  
  .text-muted {
    text-align: left;
    margin-left: 0;
  }
  .aboutheadings{
    margin-left: 0;
  }
  .abt-para-sk{
    margin-left: 1% !important;
  }
  .abt-head-sk{
    padding-top: 100px !important;
  margin-left: 0 !important;  }
}

@media (max-width: 576px) {
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.about-teamx {
  font-size: 70px;
}

.about-person {
  width: 100%;
  height: 450px;
  background-color: rgb(235, 228, 228);
}


.carousel-item{
  /* background-image: url("/public/Images/abtbannar.png"); */
  
}
#abtbannerimg{
  
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  


}
#gif-about{
 
}
.abt-heading{
  height: 100%;
  width: 100%;
  
}
.overlay-sk{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.abt-head-sk{
  font-family:Oswald, sans-serif;
  position: relative;
  z-index: 1;
  color: black;
  margin-top: 1%;
  font-size: 70px;
  font-weight: 700;
  padding-top: 0 !important;
  padding-bottom: 2% !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  text-align: center;
 
 
}
.abt-subhead{
  font-family: "tungstenw05-medium", "Oswald", sans-serif;
  position: relative;
  z-index: 1;
  color: black;
  margin-top: 0%;
  margin-left: 7%;
  font-size: 50px;
  font-weight: 700;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 15% !important;
  margin-bottom: 0 !important;
  text-align: center;
}
.abt-para-sk{
  font-family: system-ui;
  font-size: 24.4px !important;
  font-weight: 400;
  line-height: 30px;
 
  position: relative;
  z-index: 1;
  color: black;
 
  margin-left: 10%;
  font-size: 50px;
  
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  text-align: start;

}

@media only screen and (max-width:425px){
  .abt-head-sk{
    font-size: 40px;
    padding-top: 70px !important;
  }
}













.containerz .cardz:hover .cardz-body .cardz-text {
  opacity: 1;
}





.containerz .cardz:hover .cardz-img-top {
  transform: translateY(2%);
}

.containerz .cardz:hover .cardz-body {
  transform: translateY(0);
}

@media (max-width: 576px) {
  .containerz {
    flex-direction: column;
    align-items: center;
  }

  .containerz .cardz {
    width: 100%;
  }

  .containerz .cardz .cardz-img-top {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 577px) and (max-width: 1024px) {
  .containerz {
    justify-content: center;
    flex-wrap: wrap;
  }

  .containerz .cardz {
    width: 45%;
  }
}
/* Font */

/* About.css */
/* Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Body */
html {
  background-color: #5e4b4b;
}

body {
  color: #fff;
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  /* padding: 1rem; */
}

/* Main Container */
.main {
  max-width: 1200px;
  margin: 0 auto;
}

/* Header */
h1 {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

/* Images */
img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

/* Buttons */
.btn {
  padding: 0.8rem;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 400;
  display: block;
  width: 100%;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

/* Cards */
.cards {
  display: flex;
  flex-wrap: wrap;
 
  
}

.cards_item {
  display: flex;
  padding: 1rem;
}

@media (min-width: 40rem) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    width: 33.3333%;
  }
}

/* Card Styling */
.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #f2f2f2 !important;
  margin: 0 !important;

}

.card_content {
  padding: 1rem;
  
}


.card_title {
  font-family: system-ui;
  color: #0e0000;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0;
}

.card_text {
  color: #200d0d;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  font-weight: 400;
}

.made_by {
  font-weight: 400;
  font-size: 13px;
  margin-top: 50px;
  text-align: center;
}

/* Card Image */
.card_image {
  /* Adjust the height as needed */
  /* background-color: #517375; */
}



@media (min-width: 576px) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .cards_item {
    width: 33.3333%;
  }
}

@media only screen and (max-width: 1200px) {
  ._titleContainer_199mo_25 {
    grid-column: 1 / 9;
    margin-top: 4.5rem;
    gap: .5rem;
  }
}

._titleContainer_199mo_25 {
  margin-top: 7rem;
  color: #fff;
  grid-column: 1 / 7;
  margin-bottom: 3rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Additional CSS for HTML and Body */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

html, body {
  color: #111;
  font-family: Roboto;
  font-size: 16px;
}









.cards_container {
  display: flex;  /* Use flex layout to display the cards in one line */
  justify-content: space-between;  /* Distribute the space between the cards evenly */
}

.cards_item {
  flex: 2;  /* Each card should take an equal amount of space */
  margin: 0px;  /* Add some margin around each card */
}

.card_image img {
  width: fit-content;  /* Set a fixed width for all images */
  height: 100%;  /* Set a fixed height for all images */
  object-fit: cover;  /* Ensure the image covers the given dimensions */
  
}

.card_title {
  font-size: 14px;  /* Adjust the font size for the title as needed */
}



