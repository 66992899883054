.contactmaindiv {
  height: 300px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  justify-content: center;
  overflow-y: scroll;
  opacity: 1; /* Initially set opacity to 0 */
  transition: opacity 0.2s ease-in;
}
.iframe {
  width: 100%;
  height: 100%;
}
 
.visible {
  opacity: 1; /* Set opacity to 1 when visible */
}
 
.addressApp {
  overflow-x: hidden;
  width: auto;
  height: auto;
  margin-top: 100px;
}
 
.address1 {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 300px;
  gap: 1rem;
  /* border-top: 1px solid; */
  justify-content: center;
  align-items: center;
  position: relative; /* Added */
 
  opacity: 0.9;
}
.horizontal {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 10%;
  /* direction: ltr; */
}
.address1:hover {
  opacity: 1;
}
 
/* Added */
.address1::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin-left: 0px;
  width: 20px;
  background: rgb(224, 120, 9);
  z-index: 1;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
 
.address1:hover::before {
  opacity: 1;
  transform: translateY(0);
}
 
/* Added */
.address1:hover {
  overflow-x: hidden;
}
 
.add-city {
  /* height: 100%; */
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: start;
  margin-top: 5%;
}
 
.add-landmark {
  justify-content: left;
}
 
li {
  list-style-type: none;
}
 
.add-city,
.add-landmark:hover {
}
 
.add-city {
  font-size: 40px;
  color: #000;
  font-weight: 900;
  line-height: 40px;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.li-addrestile {
  font-weight: 400px;
  line-height: 1.1;
  font-size: larger;
}
.ltext {
  font-size: 20px;
  line-height: 25px;
  color: #7e7e7e;
  font-weight: 300;
}
.contactimg {
  background-image: url("/public/Images/contactus1st.jpg");
  height: 90vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: center;
  transform: scaleX(-1);
  opacity: 0.6;
}
 
@media only screen and (max-width: 768px) {
  .address1 {
    grid-template-columns: 1fr;
    height: auto;
  }
  .contactmaindiv {
    overflow: hidden;
    height: auto;
    margin: auto;
    flex-direction: column;
  }
  .add-city {
    margin-top: 0px;
  }
  .add-landmark {
    justify-content: center;
  }
  .cityheadding {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  .columnb textarea{
    width: 100% !important;
  }
  .contact-details{
    margin-top: 50px !important;
  }
}
 
/* Main container styles */
.cont {
  overflow: hidden;
}
.contact-container {
  background-image: url("https://akkim.com.tr/wp-content/uploads/2022/07/iletisim.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
 
 
  
  font-family: "Oswald", sans-serif;
  font-size: 24px;
 
  color: #fff;
 
  font-size: 18px;
}
 
.left-heading {
  margin: 0; /* Remove default margin for the heading */
}

.additional-content {
  padding: 20px;
  background-color: white;
}
 
.looking-for {
  font-size: 42px;
  text-align: center;
  margin-bottom: 50px;
  padding-top: 75px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 40px;
  font-family: tungstenw05-medium, Oswald, sans-serif;
}
/* Grid styles */
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
 
.grid-item {
  background-color: #f2f2f2; /* Set the background color to white */
  padding: 20px;
  text-align: center;
}
 
.grid-item h2 {
  font-size: 20px;
  font-weight: bold;
  font-family: myriad-pro, Arial;
  margin: 0;
  padding: 5px 0;
  color: black !important; /* Set the default color to black */
}
 
.grid-item:hover h2 {
  color: rgb(
    65,
    63,
    63
  ) !important; /* Set the color to black on hover as well */
}
 
.grid-item a {
  text-decoration: none; /* Remove the underline from anchor tags */
  color: black; /* Set the color to black to match the heading color */
}
 
.Contact_page {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: left;
  padding-top: 10px;
}
 
div.Contact_page h1 {
  color: black;
  font-size: 42px;
  font-family: tungstenw05-medium, Oswald, sans-serif;
  text-align: center;
  margin-left: 10px; /* Add a left margin to move the heading to the left */
  padding-top: 30px;
  margin-top: 10px;
  padding-bottom: 30px;
  background-image: url("https://img.freepik.com/premium-photo/banner-flowing-waves-bright-blue-background-copy-space_106885-3572.jpg?w=2000");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
 
.Contact_page p {
  color: #666;
  font-size: 16px;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center; /* Add text alignment to the left */
  margin-left: 20px; /* Add a left margin for slight adjustment */
}
 
.form-container {
  display: grid;
  padding-left: 22px;
  grid-template-columns: 1fr 1fr; /* Divide the container into two equal columns */
  gap: 20px;
}
 
.form-group {
  margin-bottom: 20px;
}
 
.form-group label {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
  font-family: "Arial", sans-serif;
}
 
/* Contact Form Inputs */
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
 
/* Contact Form Submit Button */
.submit-button {
  background-color: #09b007;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px; /* Add margin to move the button down */
  padding-left: 15px; /* Adjust the left padding to move the button to the left */
}
 
.submit-button:hover {
  background-color: #02910e;
}
 
/*Location */
 
.Locations {
  position: relative;
  margin-top: 30px;
  background: #dedede;
  background-repeat: repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  background-position: top center;
  height: 400px;
}
.Location-name {
  font-size: 42px;
  color: black;
  font-family: tungstenw05-medium, Oswald, sans-serif;
  text-align: center;
  margin-left: 10px;
  padding-top: 30px;
  margin-top: 10px;
  padding-bottom: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}
 
.address-text {
  font-size: 20px;
  color: black;
  padding-left: 30px;
  margin-bottom: 20px;
}
 
/* .maps {
  border: 0;
  margin-right: 20%;
  margin-left: 70%;
  margin-top: 50px;
  margin-top: -30%;
  width: 350px;
  height: 230px;
} */
 
.h30 {
  color: #000;
  padding-bottom: 20px;
  padding-left: 20px;
  font-size: 28px;
}
.h31 {
  color: #000;
  font-size: 28px;
  margin: 10px, 0;
  padding-bottom: 20px;
  padding-left: 20px;
}
.h32 {
  margin: 10px, 0;
  font-size: 28px;
  color: #000;
  padding-bottom: 20px;
  padding-left: 20px;
}
.contact-section {
  display: flex;
  gap: 5px;
  padding-top: 20px;
  padding-left: 15px;
}
 
.contact-column {
  flex: 1;
}
 
.contact-info {
  display: flex;
  align-items: flex-start;
}
 
.contact-icon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}
 
.contact-icon svg {
  width: 100%;
  height: 100%;
  fill: #000; /* Change the fill color as needed */
}
 
.contact-info p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}
/* css for Office */
.mainaddresdiv {
  height: 120vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.addresdiv {
  width: 100%;
  overflow: hidden;
  background: #333;
  margin: 0px;
  padding: 0px;
  height: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
 
.address1outsidediv {
  overflow: hidden;
  display: flex;
 
  font-family: system-ui;
  font-weight: 300;
  flex-wrap: wrap;
  flex-direction: column;
  width: 70%;
  margin-top: 5%;
  justify-content: start;
  height: 100%;
}
.contacth {
  width: 100%;
  /* height: 30%; */
 
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  font-family: tungstenw05-medium, Oswald, sans-serif;
  color: white;
}
.cantacth2 {
  font-size: 70px;
  line-height: 50px;
  font-weight: 500;
}
.conatacth4 {
  margin-top: 20px;
  font-size: 40px;
  font-weight: 250;
  line-height: 40px;
  font-family: system-ui;
}
 
.divforaddress {
  overflow: hidden;
  background-color: transparent;
  width: 70%;
  justify-content: center;
  align-items: left;
  height: 100%;
  margin-left: 5%;
  display: grid;
  grid-template-columns: 1fr;
}
.divforaddress-1 {
  overflow: hidden;
  background-color: transparent;
  width: 85%;
 
  align-items: center;
  justify-content: center;
  /* margin-left: 15%; */
  margin-top: 20%;
  height: 50%;
  position: absolute;
 
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
 
.address1headding-np {
  background-color: #322727;
  width: 20px;
}
 
.address1headding {
  /* background-color: #ccc; */
}
 
.address1div-np {
  border: 1px white;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
 
  font-family: system-ui;
}
 
.address1div-np img {
  width: 100%;
  z-index: 1;
  position: absolute;
  height: 100%;
  object-fit: cover;
  flex-wrap: wrap;
  display: flex;
  transition: all 0.3s ease-in-out;
  /* top: 0px;
  left: 0px; */
  /* bottom: 0px; */
}
.americas {
}
 
.address1div-np p {
  color: white;
  font-size: 45px;
  font-weight: 900;
  letter-spacing: -0.5px;
  position: absolute;
  /* top: 50%; */
  z-index: 222;
  /* left: 0; */
  height: 100%;
  align-items: center;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  bottom: 0px;
  top: 0px;
}
.address1div-np p:hover {
  transition: all 0.3s ease-in-out;
  background-color: rgba(237, 160, 16, 0.647);
}
 
.asia-pacific {
}
.middal-east {
  background-image: url();
}
.americas {
}
#contacttitle{
  margin-top: 20%;
  margin-left: 10% !important;
  text-align: start !important;
  color: #fff !important;
}
 
/* responsive */
@media only screen and (max-width: 500px) {
  .address1outsidediv {
    /* height: 40%;
    width: 80%; */
  }
  #contacttitle{
    margin-left: 0 !important;
  }
  .divforaddress-1 {
    margin-top: 50%;
    height: 45%;
    width: 95%;
    /* height: 40%; */
  }
  .address1div-np p {
    font-size: 35px;
  }
 
  .address1div {
    height: 100vh;
  }
 
  .address1headding-np {
  }
  .divforaddress {
    grid-template-columns: 1fr;
    /* margin-left: 14%; */
  }
  .middal-east {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .cantacth2 {
    height: auto;
    font-size: 37px;
    line-height: 35px;
    font-weight: 400;
    font-family: tungstenw05-medium, Oswald, sans-serif;
    color: #fff !important;
  }
  .conatacth4 {
    margin-top: 0 !important;
    font-size: 25px;
    line-height: 30px !important; 
  }
  .mainaddresdiv {
    height: 90vh;
  }
  .contact-details{
    margin-left: 0 !important;
  }
  .abt-subhead{
    padding-top: 20% !important;
    margin-left: 0 !important;
  }
  .cont{
    padding: 0 !important;
  }
  .formaind{
    padding: 0 !important;
  }
  .rows{
    padding: 0 !important;
  }
}
/* responsive for ipad  */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .mainaddresdiv {
    height: 60vh;
  }
  .addresdiv {
    width: 100%;
    height: 65%;
  }
 
  .address1outsidediv {
    flex-wrap: wrap;
    width: 80%;
    height: 100%;
  }
 
  .cantacth2 {
    font-size: 50px;
    line-height: 50px;
    font-weight: 500;
  }
  .conatacth4 {
    /* margin-top: -2%; */
    font-size: 40px;
    font-weight: 300;
    line-height: 40px;
    font-family: system-ui;
  }
 
  .divforaddress {
    overflow: hidden;
    justify-content: center;
    width: 80%;
    /* margin-left: 10%; */
    height: 100%;
  }
  .divforaddress-1 {
    width: 90%;
    margin-top: 33%;
    height: 30%;
  }
 
  .address1headding-np {
    background-color: #322727;
    width: 20px;
  }
  .abt-subhead{
    margin-top: 15% !important;
    

  }
}
/* responsive for nest hub */
@media only screen and (min-width: 1025px) and (max-width: 1085px) {
  .mainaddresdiv {
    height: 65vh;
  }
  .addresdiv {
    width: 100%;
    height: 55%;
  }
 
  .address1outsidediv {
    width: 80%;
  }
 
  .cantacth2 {
    font-size: 50px;
    line-height: 50px;
    font-weight: 500;
  }
  .conatacth4 {
    font-size: 35px;
    font-weight: 300;
    /* margin-top: -3%; */
    line-height: 30px;
    font-family: system-ui;
  }
 
  .divforaddress {
    overflow: hidden;
 
    height: 70%;
  }
  .divforaddress-1 {
    /* width: 80%; */
    margin-top: 27%;
    height: 40%;
  }
 
  .address1headding-np {
    background-color: #322727;
    width: 20px;
  }
  .conatacth4 {
  }
}
@media only screen and (min-width: 501px) and (max-width: 599px) {
  .divforaddress {
    grid-template-columns: 1fr;
    height: 70%;
  }
  .divforaddress-1 {
    margin-top: 60%;
    height: 100%;
    width: 70%;
  }
  .conatacth4 {
    font-size: 30px;
  }
  .divforaddress {
  }
  .address1div-np {
  }
}
 

/*----------------------*/
.contact-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 17% !important;
  margin-left: 0;
}

.contact-item {
  display: flex;
  align-items: center;
  margin: 20px;
  font-size: 20px;
}

.contact-item i {
  color: white;
  font-size: 30px;
  margin-right: 20px;
}

.contact-info {
  margin-left: 10px;
}
.contact-info p{
  font-size: 20px;
}

.contact-info h3 {
  margin-bottom: 10px;
}
