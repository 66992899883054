.mortgages-page {
  background: #fff;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Hero Section */
.mortgage-hero {
  margin-top: 0;
  background: linear-gradient(135deg, #8B4513 0%, #D2691E 100%);
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.mortgage-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.hero-content {
  position: relative;
  z-index: 1;
}

.hero-text-content {
  padding-right: 2rem;
}

.hero-title {
  font-size: 3.5rem !important;
  font-weight: 700 !important;
  color: #fff !important;
  line-height: 1.1 !important;
  margin-bottom: 2rem !important;
  text-align: left !important;
  position: relative !important;
  letter-spacing: -0.5px !important;
}

.hero-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 80px;
  height: 3px;
  background: #fff;
  border-radius: 2px;
}

.hero-title .highlight {
  color: #fff !important;
  background: none;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
  background-clip: initial;
}

.hero-cta {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.start-button {
  background: linear-gradient(135deg, #D2691E, #8B4513) !important;
  color: #fff !important;
  padding: 1rem 2rem !important;
  font-size: 1.1rem !important;
  border-radius: 12px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  transition: all 0.3s ease !important;
  box-shadow: 0 4px 15px rgba(139, 69, 19, 0.3) !important;
}

.start-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(139, 69, 19, 0.4) !important;
}

.rating-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 12px;
  backdrop-filter: blur(10px);
}

.rating-text {
  color: rgba(255, 255, 255, 0.9) !important;
  font-size: 0.9rem !important;
}

.hero-features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  margin-top: 2rem;
  max-width: 100%;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 0.875rem;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem 1.25rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  min-width: 0;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-item:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border-color: rgba(255, 255, 255, 0.25);
}

.feature-item svg {
  width: 22px;
  height: 22px;
  color: #fff;
  opacity: 0.95;
  flex-shrink: 0;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
}

.feature-item p {
  color: #fff !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  margin: 0 !important;
  letter-spacing: 0.2px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.hero-image-container {
  position: relative;
  padding: 1rem;
}

.hero-image {
  width: 100%;
  max-height: 60vh;
  object-fit: cover;
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
  transform: perspective(1000px) rotateY(-5deg);
  border: 4px solid rgba(255, 255, 255, 0.1);
}

.floating-card {
  position: absolute;
  bottom: 1.5rem;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  animation: float 6s ease-in-out infinite;
}

.floating-card h6 {
  color: #8B4513;
  margin-bottom: 0.5rem;
}

.floating-card h4 {
  color: #D2691E;
  font-weight: 700;
  margin-top: 0.5rem;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 960px) {
  .mortgage-hero {
    min-height: auto;
    height: auto;
    padding: 5rem 1.5rem;
  }

  .hero-text-content {
    padding: 0;
    text-align: left;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }

  .hero-title {
    font-size: 3.2rem !important;
    text-align: left !important;
    margin-bottom: 2.5rem !important;
    line-height: 1.1 !important;
  }

  .hero-title::after {
    left: 0;
    transform: none;
    width: 100px;
  }

  .hero-features {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
    margin-top: 3rem;
  }

  .feature-item {
    padding: 1.25rem;
    background: rgba(255, 255, 255, 0.15);
  }

  .feature-item p {
    font-size: 1rem !important;
  }

  .hero-image-container, .floating-card {
    display: none !important;
  }

  .hero-cta {
    margin-top: 2.5rem;
    align-items: flex-start;
  }

  .start-button {
    padding: 1.25rem 2.5rem !important;
    font-size: 1.2rem !important;
    width: auto;
  }

  .rating-box {
    padding: 1.25rem;
    margin-top: 2rem;
    width: auto;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
  }

  .compliance-section {
    padding: 50px 15px;
  }

  .compliance-title {
    font-size: 1.8rem !important;
    margin-bottom: 1.75rem !important;
  }
}

@media (max-width: 600px) {
  .mortgage-hero {
    padding: 4rem 1.25rem;
  }

  .hero-text-content {
    padding: 0;
  }

  .hero-title {
    font-size: 2.5rem !important;
    margin-bottom: 2rem !important;
    line-height: 1.2 !important;
  }

  .hero-title::after {
    width: 80px;
    height: 4px;
  }

  .hero-features {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 2.5rem;
  }

  .feature-item {
    padding: 1rem 1.25rem;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .feature-item svg {
    width: 24px;
    height: 24px;
  }

  .feature-item p {
    font-size: 0.95rem !important;
    font-weight: 600 !important;
  }

  .start-button {
    padding: 1.1rem 2rem !important;
    font-size: 1.1rem !important;
    width: 100%;
    justify-content: center;
    text-align: center;
  }

  .rating-box {
    padding: 1.1rem;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 1.25rem;
    margin-top: 1.5rem;
  }

  .rating-text {
    font-size: 0.95rem !important;
    text-align: left;
  }

  .compliance-section {
    padding: 40px 15px;
    margin: 30px 0;
  }

  .compliance-title {
    font-size: 1.6rem !important;
    margin-bottom: 1.5rem !important;
    padding: 0 10px;
  }

  .compliance-card {
    padding: 1.25rem;
  }

  .requirement-title {
    font-size: 1.1rem !important;
    margin-bottom: 0.75rem !important;
  }
}

@media (max-width: 400px) {
  .mortgage-hero {
    padding: 3.5rem 1rem;
  }

  .hero-title {
    font-size: 2.2rem !important;
    margin-bottom: 1.75rem !important;
  }

  .feature-item {
    padding: 0.875rem 1rem;
  }

  .feature-item svg {
    width: 22px;
    height: 22px;
  }

  .feature-item p {
    font-size: 0.9rem !important;
  }

  .start-button {
    padding: 1rem 1.75rem !important;
    font-size: 1rem !important;
  }

  .rating-box {
    padding: 1rem;
    gap: 1rem;
  }

  .rating-text {
    font-size: 0.9rem !important;
  }

  .compliance-section {
    padding: 30px 12px;
    margin: 20px 0;
  }

  .compliance-title {
    font-size: 1.4rem !important;
    margin-bottom: 1.25rem !important;
    padding: 0 8px;
  }

  .compliance-title::after {
    width: 50px;
    height: 2px;
    bottom: -8px;
  }
}

/* Why Better Section */
.why-better-section {
  background: #fff;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  margin: 0;
}

.why-better-title {
  font-size: 3.5rem !important;
  font-weight: 800 !important;
  color: #8B4513 !important;
  line-height: 1.2 !important;
  margin-bottom: 40px !important;
  text-align: center !important;
  position: relative !important;
}

.why-better-description {
  font-size: 1.2rem !important;
  color: #4A5568 !important;
  line-height: 1.6 !important;
  margin: 0 0 30px !important;
  text-align: left !important;
}

.testimonial-image {
  width: 100% !important;
  height: 500px !important;
  object-fit: cover !important;
  border-radius: 24px !important;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15) !important;
}

.learn-more-button {
  background: #8B4513 !important;
  color: #fff !important;
  padding: 12px 32px !important;
  font-size: 1.1rem !important;
  border-radius: 12px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  box-shadow: 0 4px 15px rgba(139, 69, 19, 0.2) !important;
  transition: all 0.3s ease !important;
  float: left !important;
}

.learn-more-button:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 6px 20px rgba(139, 69, 19, 0.3) !important;
  background: #D2691E !important;
}

.social-proof {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
}

.platform {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Questions Section */
.questions-section {
  background: rgba(139, 69, 19, 0.05);
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 60px 0;
  margin: 80px 0;
}

.questions-section .section-title {
  font-size: 4rem !important;
  font-weight: 800 !important;
  color: #8B4513 !important;
  margin-bottom: 60px !important;
  text-align: center !important;
  position: relative !important;
}

.questions-section .section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #8B4513;
  border-radius: 2px;
}

.info-card {
  background: #fff;
  border-radius: 24px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  height: 100%;
  border: 2px solid rgba(139, 69, 19, 0.1);
  box-shadow: 0 12px 30px rgba(139, 69, 19, 0.1);
  position: relative;
  isolation: isolate;
}

.info-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05));
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 1;
}

.info-card:hover {
  transform: translateY(-15px) scale(1.02);
  box-shadow: 0 20px 40px rgba(139, 69, 19, 0.2);
  border-color: #8B4513;
}

.info-card:hover::before {
  opacity: 1;
}

.card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: 2;
}

.card-image {
  width: 100%;
  height: 280px;
  object-fit: cover;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-bottom: 4px solid rgba(139, 69, 19, 0.1);
}

.info-card:hover .card-image {
  transform: scale(1.1);
  filter: brightness(1.1) contrast(1.1);
}

.card-title {
  padding: 28px 24px 16px;
  color: #8B4513 !important;
  font-size: 1.8rem !important;
  font-weight: 800 !important;
  position: relative;
  transition: all 0.3s ease;
}

.card-title::after {
  content: '';
  position: absolute;
  bottom: 8px;
  left: 24px;
  width: 60px;
  height: 3px;
  background: #8B4513;
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: left;
}

.info-card:hover .card-title::after {
  transform: scaleX(1);
}

.info-card:hover .card-title {
  color: #D2691E !important;
  transform: translateX(10px);
}

.card-button {
  color: #8B4513 !important;
  text-transform: none !important;
  font-weight: 700 !important;
  padding: 16px 24px !important;
  justify-content: flex-start !important;
  transition: all 0.3s ease !important;
  margin-top: auto !important;
  position: relative;
  overflow: hidden;
  background: transparent !important;
}

.card-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(139, 69, 19, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.info-card:hover .card-button::before {
  transform: translateX(0);
}

.card-button:hover {
  color: #D2691E !important;
  padding-left: 32px !important;
}

/* Style for description text */
.info-card .MuiTypography-body2 {
  padding: 0 24px 20px !important;
  color: #4A5568 !important;
  font-size: 1.1rem !important;
  line-height: 1.8 !important;
  transition: all 0.3s ease;
}

.info-card:hover .MuiTypography-body2 {
  color: #2D3748 !important;
  transform: translateX(5px);
}

/* Remove bullet points and adjust spacing */
.info-card .MuiTypography-body2 {
  display: block;
  padding: 0 24px 20px !important;
}

@media (max-width: 768px) {
  .info-card {
    margin: 10px 0;
  }
  
  .card-image {
    height: 220px;
  }
  
  .card-title {
    font-size: 1.5rem !important;
    padding: 20px 20px 12px;
  }
  
  .info-card .MuiTypography-body2 {
    font-size: 1rem !important;
    padding: 0 20px 16px !important;
  }
}

.action-button {
  color: #8B4513 !important;
  border: 2px solid #8B4513 !important;
  text-transform: none !important;
  padding: 12px 32px !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  gap: 0.75rem !important;
  transition: all 0.3s ease !important;
  border-radius: 12px !important;
  font-size: 1.1rem !important;
  margin-top: 60px !important;
}

.action-button:hover {
  background: rgba(139, 69, 19, 0.05) !important;
  border-color: #D2691E !important;
  color: #D2691E !important;
  transform: translateY(-2px) !important;
}

.button-icon {
  width: 24px;
  height: 24px;
}

/* Tools Section */
.tools-section {
  background: #fff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 60px 0;
  margin: 80px 0;
}

.tools-section .section-title {
  font-size: 3rem !important;
  font-weight: 800 !important;
  color: #8B4513 !important;
  line-height: 1.2 !important;
  margin-bottom: 40px !important;
  text-align: center !important;
  position: relative !important;
}

.tools-section .section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #8B4513;
  border-radius: 2px;
}

.tool-card {
  position: relative;
  width: 100%;
  height: 300px;
  perspective: 1000px;
  cursor: pointer;
}

.tool-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(139, 69, 19, 0.1);
}

.tool-card:hover .tool-card-inner {
  transform: rotateY(180deg);
}

.tool-card-front, .tool-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  padding: 2rem;
  border-radius: 16px;
  background: linear-gradient(145deg, #fff, #f8f9fa);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tool-card-back {
  transform: rotateY(180deg);
  background: linear-gradient(135deg, #8B4513, #D2691E);
  color: white;
}

.tool-icon {
  width: 48px;
  height: 48px;
  color: #8B4513;
  margin-bottom: 1.5rem;
}

.tool-title {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: #8B4513;
  margin-bottom: 1rem !important;
}

.tool-description {
  color: #4A5568;
  line-height: 1.6;
}

.tool-card-back .tool-title {
  color: white !important;
  margin-bottom: 1.5rem !important;
}

.tool-card-back .service-details {
  text-align: left;
  width: 100%;
}

.service-detail-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.8rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.95rem;
}

.service-detail-item svg {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

/* FAQ Section */
.faq-section {
  background: #f8f9fa;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 80px 0;
  margin-bottom: 0;
}

.faq-section .section-header {
  text-align: center;
  margin-bottom: 50px;
  display: block !important;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.faq-heading {
  font-size: 4rem !important;
  font-weight: 800 !important;
  color: #8B4513 !important;
  margin-bottom: 16px !important;
  position: relative !important;
  display: block !important;
  text-align: center !important;
  line-height: 1.2 !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.faq-heading::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #8B4513;
  border-radius: 2px;
}

.faq-subheading {
  font-size: 1.8rem !important;
  font-weight: 600 !important;
  color: #4A5568 !important;
  margin-top: 30px !important;
  display: block !important;
  text-align: center !important;
  line-height: 1.4 !important;
  max-width: 600px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-transform: uppercase !important;
  letter-spacing: 0.5px !important;
}

/* Calculator Modal Styles */
.calculator-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.calculator-modal-content {
  background: #fff;
  border-radius: 24px;
  width: 100%;
  max-width: 560px;
  max-height: 90vh;
  overflow: auto;
  position: relative;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
}

/* Calculator Grid */
.calculator-grid {
  margin-top: 4rem;
}

.calculator-card {
  background: #fff;
  border-radius: 16px;
  padding: 2rem;
  height: 100%;
  border: 1px solid rgba(139, 69, 19, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.calculator-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 30px rgba(139, 69, 19, 0.1);
}

.calculator-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 1.5rem;
}

.calculator-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.calculator-title {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  color: #8B4513 !important;
  margin-bottom: 0.5rem !important;
}

.calculator-description {
  color: #666 !important;
  margin-bottom: 1.5rem !important;
  flex-grow: 1;
}

.calculator-button {
  color: #8B4513 !important;
  text-transform: none !important;
  font-weight: 600 !important;
  padding: 0 !important;
  align-self: flex-start;
}

/* Calculator Section */
.calculator-section {
  padding: 80px 0;
  background: #fff;
}

.calculator-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.calculator-option {
  width: 100%;
  padding: 1rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  border-radius: 12px !important;
  color: #8B4513 !important;
  font-weight: 500 !important;
  text-transform: none !important;
  transition: all 0.3s ease !important;
  border: 1px solid rgba(139, 69, 19, 0.1) !important;
}

.calculator-option.active {
  background: rgba(139, 69, 19, 0.1) !important;
  font-weight: 600 !important;
}

.calculator-option:hover {
  background: rgba(139, 69, 19, 0.05) !important;
  transform: translateX(4px);
}

.calculator-form {
  background: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(139, 69, 19, 0.1);
  border: 1px solid rgba(139, 69, 19, 0.1);
}

.calculator-slider {
  color: #8B4513 !important;
}

.calculator-slider .MuiSlider-thumb {
  width: 16px;
  height: 16px;
  background: #8B4513;
}

.calculator-slider .MuiSlider-track {
  background: #8B4513;
}

.calculate-button {
  background: linear-gradient(135deg, #D2691E, #8B4513) !important;
  color: #fff !important;
  padding: 12px 32px !important;
  font-size: 1rem !important;
  border-radius: 8px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  margin-top: 1rem !important;
  transition: all 0.3s ease !important;
}

.calculate-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(139, 69, 19, 0.3) !important;
}

.calculator-result {
  margin-top: 2rem;
  padding: 2rem;
  background: rgba(139, 69, 19, 0.05);
  border-radius: 12px;
  text-align: center;
}

.result-label {
  color: #8B4513 !important;
  margin-bottom: 0.5rem !important;
}

.result-amount {
  color: #8B4513 !important;
  font-weight: 700 !important;
}

.calculator-bento {
  cursor: pointer;
}

.calculator-bento:hover {
  transform: translateY(-4px);
  background: rgba(139, 69, 19, 0.05);
}

/* Responsive styles */
@media (max-width: 1200px) {
  .bento-grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 30px;
  }
  
  .bento-card:nth-child(1) {
    grid-column: span 1;
    height: 220px;
  }
}

@media (max-width: 768px) {
  .section-header {
    padding: 40px 20px 20px;
  }

  .section-header .section-title {
    font-size: 2rem !important;
  }

  .header-buttons {
    width: 100%;
    overflow-x: auto;
    padding: 4px;
  }

  .header-button {
    padding: 10px 16px !important;
    font-size: 0.875rem !important;
  }

  .bento-grid {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .bento-content {
    width: 65%;
  }

  .bento-image-wrapper {
    width: 35%;
  }

  .bento-title {
    font-size: 1.2rem !important;
  }

  .calculator-section {
    padding: 40px 0;
  }

  .calculator-options {
    margin-bottom: 2rem;
  }

  .calculator-form {
    padding: 24px;
  }

  .calculator-modal-content {
    margin: 0 16px;
  }

  .calculator-modal-header {
    padding: 20px 24px;
  }

  .mortgage-hero,
  .why-better-section,
  .tools-section,
  .questions-section,
  .faq-section {
    margin: 40px 0;
    min-height: auto;
    padding: 60px 0;
  }
  
  .mortgage-hero {
    margin-top: 0;
  }
  
 .faq-section {
    margin-bottom: 0;
  }

  .faq-heading {
    font-size: 2.5rem !important;
  }
  
  .faq-subheading {
    font-size: 1.4rem !important;
  }

  .tool-card {
    padding: 24px;
  }
  
  .tool-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
  }
  
  .tool-title {
    font-size: 1.3rem !important;
  }

  .tools-section .section-title {
    font-size: 2.5rem !important;
    margin-bottom: 30px !important;
  }

  .tool-description {
    font-size: 1rem !important;
  }
}

@media (max-width: 600px) {
  .tools-section {
    padding: 40px 0;
    margin: 40px 0;
  }

  .tools-section .section-title {
    font-size: 2rem !important;
    margin-bottom: 25px !important;
    padding: 0 15px;
  }

  .tool-title {
    font-size: 1.2rem !important;
    margin-bottom: 12px !important;
  }

  .tool-description {
    font-size: 0.95rem !important;
    line-height: 1.5 !important;
  }

  .tool-card {
    padding: 20px !important;
  }

  .tool-icon {
    width: 48px !important;
    height: 48px !important;
    margin-bottom: 16px !important;
  }
}

@media (max-width: 400px) {
  .tools-section {
    padding: 30px 0;
    margin: 30px 0;
  }

  .tools-section .section-title {
    font-size: 1.75rem !important;
    margin-bottom: 20px !important;
  }

  .tool-title {
    font-size: 1.1rem !important;
    margin-bottom: 10px !important;
  }

  .tool-description {
    font-size: 0.9rem !important;
  }

  .tool-card {
    padding: 16px !important;
  }

  .tool-icon {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 12px !important;
  }
}

/* Common card styling for all sections */
.card-base {
  background: #ffffff !important;
  border: 2px solid #e2e8f0 !important;
  border-radius: 16px !important;
  padding: 20px !important;
  height: 100% !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease !important;
  cursor: pointer !important;
  margin-bottom: 20px !important;
}

.card-base:hover {
  transform: translateY(-5px) !important;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15) !important;
}

.card-title {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: #1A202C !important;
  margin-bottom: 16px !important;
  line-height: 1.4 !important;
}

.card-description {
  font-size: 1rem !important;
  color: #4A5568 !important;
  line-height: 1.6 !important;
  margin-bottom: 24px !important;
}

.card-button {
  color: #8B4513 !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  text-transform: none !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
  background: rgba(139, 69, 19, 0.1) !important;
  transition: all 0.3s ease !important;
}

.card-button:hover {
  background: rgba(139, 69, 19, 0.2) !important;
}

.testimonial-image {
  width: 100%;
  max-width: 500px;
  height: 400px;
  margin: 20px 0;
  object-fit: cover;
  border-radius: 20px;
  border: 8px solid #fff;
  box-shadow: 0 10px 30px rgba(139, 69, 19, 0.15);
  transition: transform 0.3s ease;
}

.testimonial-image:hover {
  transform: translateY(-5px);
}

/* Ensure container content is properly centered */
.mortgage-hero .container,
.why-better-section .container,
.tools-section .container,
.questions-section .container,
.faq-section .container {
  width: 100%;
  padding: 40px 0;
}

.section-title {
  font-size: 3.5rem !important;
  font-weight: 800 !important;
  color: #8B4513 !important;
  line-height: 1.2 !important;
  margin-bottom: 40px !important;
  text-align: center !important;
  position: relative !important;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #8B4513;
  border-radius: 2px;
}

@media (max-width: 960px) {
  .section-title {
    font-size: 2.5rem !important;
    margin-bottom: 30px !important;
  }

  .why-better-section {
    min-height: auto;
    height: auto;
    padding: 5rem 1.5rem;
    margin: 2rem 0;
  }

  .why-better-title {
    font-size: 3rem !important;
    text-align: center !important;
    margin-bottom: 2.5rem !important;
    padding: 0 1rem;
  }

  .why-better-description {
    font-size: 1.1rem !important;
    text-align: center !important;
    padding: 0 1rem;
    margin-bottom: 2rem !important;
  }

  .learn-more-button {
    float: none !important;
    display: inline-block !important;
    margin: 1.5rem auto !important;
    padding: 1.25rem 2.5rem !important;
    font-size: 1.2rem !important;
  }

  .social-proof {
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 1rem;
  }
}

@media (max-width: 600px) {
  .section-title {
    font-size: 1.8rem !important;
    margin-bottom: 25px !important;
    padding: 0 10px;
  }

  .section-title::after {
    width: 60px;
    height: 3px;
    bottom: -10px;
  }

  .why-better-section {
    padding: 4rem 1rem;
    margin: 1rem 0;
  }

  .why-better-title {
    font-size: 2.5rem !important;
    margin-bottom: 2rem !important;
    line-height: 1.2 !important;
  }

  .why-better-description {
    font-size: 1rem !important;
    line-height: 1.6 !important;
    margin-bottom: 1.5rem !important;
  }

  .learn-more-button {
    width: 100% !important;
    padding: 1.1rem 2rem !important;
    font-size: 1.1rem !important;
    text-align: center !important;
    justify-content: center !important;
  }

  .social-proof {
    gap: 1.5rem;
    margin-top: 2.5rem;
  }

  .platform {
    flex: 0 0 calc(50% - 0.75rem);
  }
}

@media (max-width: 400px) {
  .section-title {
    font-size: 1.8rem !important;
    margin-bottom: 1.75rem !important;
  }

  .section-title::after {
    width: 60px;
    height: 3px;
    bottom: -10px;
  }

  .why-better-section {
    padding: 3rem 1rem;
  }

  .why-better-title {
    font-size: 2.2rem !important;
    margin-bottom: 1.75rem !important;
  }

  .why-better-description {
    font-size: 0.95rem !important;
  }

  .learn-more-button {
    padding: 1rem 1.75rem !important;
    font-size: 1rem !important;
  }

  .social-proof {
    gap: 1rem;
  }
}

/* Compliance and Requirements Section */
.compliance-section {
  background: rgba(139, 69, 19, 0.03);
  padding: 60px 20px;
  margin: 40px 0;
}

.compliance-section .compliance-title {
  font-size: 3.5rem !important;
  font-weight: 800 !important;
  color: #8B4513 !important;
  line-height: 1.2 !important;
  margin-bottom: 40px !important;
  text-align: center !important;
  position: relative !important;
}

.compliance-section .compliance-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #8B4513;
  border-radius: 2px;
}

.compliance-card {
  background: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  height: 100%;
  border: 1px solid rgba(139, 69, 19, 0.1);
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(139, 69, 19, 0.05);
  margin-bottom: 1rem;
}

.requirement-title {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  color: #8B4513 !important;
  margin-bottom: 1rem !important;
  line-height: 1.3 !important;
}

.requirement-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.requirement-item {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin-bottom: 0.875rem;
  color: #4A5568;
  font-size: 0.95rem;
  line-height: 1.5;
}

.requirement-icon {
  color: #8B4513;
  flex-shrink: 0;
  margin-top: 3px;
  width: 18px;
  height: 18px;
}

@media (max-width: 600px) {
  .compliance-section {
    padding: 40px 15px;
    margin: 30px 0;
  }

  .compliance-section .compliance-title {
    font-size: 1.8rem !important;
    margin-bottom: 25px !important;
    padding: 0 10px !important;
  }

  .compliance-section .compliance-title::after {
    width: 60px;
    height: 3px;
    bottom: -10px;
  }

  .compliance-card {
    padding: 1.25rem;
  }

  .requirement-title {
    font-size: 1.1rem !important;
    margin-bottom: 0.75rem !important;
  }

  .requirement-item {
    font-size: 0.9rem;
    gap: 0.625rem;
    margin-bottom: 0.75rem;
  }

  .requirement-icon {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 400px) {
  .compliance-section {
    padding: 30px 12px;
    margin: 20px 0;
  }

  .compliance-section .compliance-title {
    font-size: 1.8rem !important;
    margin-bottom: 20px !important;
  }

  .compliance-section .compliance-title::after {
    width: 50px;
    height: 2px;
    bottom: -8px;
  }

  .compliance-card {
    padding: 1rem;
  }

  .requirement-title {
    font-size: 1rem !important;
    margin-bottom: 0.625rem !important;
  }

  .requirement-item {
    font-size: 0.875rem;
    gap: 0.5rem;
    margin-bottom: 0.625rem;
  }

  .requirement-icon {
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }
} 