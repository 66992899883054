/* Modern Dropdown Menu Styles */
.dropdown {
  position: relative;
  display: inline-block;
  font-family: system-ui;
}

.ok {
  color: rgb(252, 248, 248);
  text-decoration: none;
  font-family: system-ui;
  position: relative;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.ok:hover {
  text-decoration: none;
  color: #D2691E;
}

/* Glowing effect for menu items */
.ok::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  background: radial-gradient(circle, rgba(210, 105, 30, 0.2), transparent);
  border-radius: 50%;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: -1;
}

.ok:hover::before {
  width: 150%;
  height: 150%;
}

.dropdown-content,
.dropdown-content-2,
.dropdown-content-3,
.dropdown-content-4,
.dropdown-content-5 {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  width: max-content;
  min-width: 600px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 
    0 10px 30px rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  padding: 1.5rem;
  z-index: 1001;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 10px;
}

/* Floating effect on hover */
.dropdown:hover .dropdown-content,
.dropdown:hover .dropdown-content-2,
.dropdown:hover .dropdown-content-3,
.dropdown:hover .dropdown-content-4,
.dropdown:hover .dropdown-content-5 {
  display: block;
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%) translateY(0);
}

@keyframes float {
  0%, 100% { transform: translateX(-50%) translateY(0); }
  50% { transform: translateX(-50%) translateY(-5px); }
}

/* Modern grid layout */
.dard1, .dard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  position: relative;
}

/* Card design for links */
.link-aj {
  text-decoration: none;
  color: #333;
  padding: 1rem;
  border-radius: 16px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4));
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 0.95rem;
  position: relative;
  overflow: hidden;
}

/* Shine effect on hover */
.link-aj::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transform: rotate(45deg);
  transition: 0.5s;
}

.link-aj:hover::after {
  left: 100%;
}

.link-aj:hover {
  background: linear-gradient(135deg, rgba(139, 69, 19, 0.1), rgba(210, 105, 30, 0.05));
  transform: translateY(-2px) scale(1.02);
  box-shadow: 
    0 10px 20px rgba(139, 69, 19, 0.1),
    0 0 0 1px rgba(139, 69, 19, 0.1);
  color: #8B4513;
}

.allplatforms {
  font-family: system-ui;
  background: linear-gradient(45deg, #8B4513, #D2691E);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.allplatforms::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 50px;
  height: 3px;
  background: linear-gradient(90deg, #8B4513, transparent);
  border-radius: 2px;
}

.linehr {
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, rgba(139, 69, 19, 0.2), transparent);
  margin: 1rem 0;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .dropdown-content,
  .dropdown-content-2,
  .dropdown-content-3,
  .dropdown-content-4,
  .dropdown-content-5 {
    min-width: 500px;
  }

  .dard1, .dard {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .dropdown-content,
  .dropdown-content-2,
  .dropdown-content-3,
  .dropdown-content-4,
  .dropdown-content-5 {
    min-width: 300px;
    left: 0;
    transform: translateX(0) translateY(0);
  }

  .dard1, .dard {
    grid-template-columns: 1fr;
  }
}

/* Staggered animation for menu items */
.link-aj {
  animation: slideIn 0.3s ease forwards;
  opacity: 0;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.dard1 .link-aj:nth-child(1) { animation-delay: 0.1s; }
.dard1 .link-aj:nth-child(2) { animation-delay: 0.15s; }
.dard1 .link-aj:nth-child(3) { animation-delay: 0.2s; }
.dard1 .link-aj:nth-child(4) { animation-delay: 0.25s; }
.dard1 .link-aj:nth-child(5) { animation-delay: 0.3s; }
.dard1 .link-aj:nth-child(6) { animation-delay: 0.35s; }

/* Case Studies Dropdown Specific Styles */
.dropdown-content-5 {
    min-width: 800px !important;
    position: absolute;
    right: 0 !important;
    transform: translateX(-100%) translateY(20px) !important;
    margin-right: 100%; /* This ensures it opens to the left */
}

.dropdown:hover .dropdown-content-5 {
    transform: translateX(-100%) translateY(0) !important;
}

/* Responsive adjustments for case studies dropdown */
@media (max-width: 992px) {
    .dropdown-content-5 {
        min-width: 600px !important;
    }
}

@media (max-width: 768px) {
    .dropdown-content-5 {
        min-width: 300px !important;
        transform: none !important;
        left: 0 !important;
        right: auto !important;
        margin-right: 0;
    }
    
    .dropdown:hover .dropdown-content-5 {
        transform: none !important;
    }
}

.case-study-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 12px;
    margin-bottom: 1rem;
}

/* Updated styles for section titles */
.case-study-section .section-title {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    color: #8B4513 !important;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(74, 85, 104, 0.2);
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.dropdown-content-5 .dard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 1rem;
}

/* Style for the links */
.case-study-section .link-aj {
    font-size: 0.85rem;
    color: #2D3748;
    transition: all 0.3s ease;
    padding: 0.25rem 0;
}

.case-study-section .link-aj:hover {
    color: #8B4513;
    transform: translateX(5px);
}
