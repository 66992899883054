@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");
 
#maindivs {
  overflow-x: hidden;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
/* *{
  overflow-x: hidden;
} */
 
/* First slider */
 
/* ............ */
.component-1 {
  overflow: hidden;
padding-bottom: 50px;
  font-size: 60px;
  /* line-height: 40px; */
  font-weight: 500;
  font-family: "Roboto Slab", serif;
  color: white;
 
  display: flex;
  flex-wrap: wrap;
 
  justify-content: center;
}
.component-paras {
  display: flex;
  margin-top: -50%;
  font-size: 28px;
  
 
  justify-content: center;
}
.carousel-caption {
  overflow: hidden;
}
 
.container-np2 {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #FFF5E6 0%, #FFF8ED 100%);
  flex-wrap: wrap;
  min-height: 80vh;
  width: 100vw;
  padding: 6rem 0;
  position: relative;
}

.container-np2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(#8B4513 1px, transparent 1px);
  background-size: 30px 30px;
  opacity: 0.05;
  pointer-events: none;
}

.cont2-titles {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-bottom: 4rem;
  font-size: 3rem;
  font-weight: 800;
  font-family: "Roboto Slab", serif;
  color: #8B4513;
  text-align: center;
  position: relative;
  line-height: 1.2;
}

.container21 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  gap: 6rem;
  padding: 0 2rem;
  align-items: center;
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  padding: 3rem;
  box-shadow: 
    0 20px 40px rgba(139, 69, 19, 0.05),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.container21::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 30px;
  padding: 2px;
  background: linear-gradient(45deg, rgba(139, 69, 19, 0.3), rgba(255, 255, 255, 0.5));
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.cont2-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 2rem;
  border-radius: 20px;
}

.left-text {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #4A5568;
  text-align: justify;
  padding-right: 2rem;
  position: relative;
  z-index: 1;
}

.cont2-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem;
}

.content2img {
  width: 300px;
  height: 300px;
  object-fit: contain;
  border-radius: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 
    0 10px 30px rgba(139, 69, 19, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.9);
  transition: all 0.3s ease;
  transform: translateY(0);
}

.content2img:hover {
  transform: translateY(-10px);
  box-shadow: 
    0 20px 40px rgba(139, 69, 19, 0.15),
    inset 0 0 0 2px rgba(139, 69, 19, 0.1);
}

.readmore {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .content2img {
    width: 250px;
    height: 250px;
    padding: 15px;
  }
}
 
.carousel-inner{
  margin: 0 !important;
  padding: 0 !important;
  }
.read1 {
  width: 180px;
  height: 50px;
 
  font-size: 15px;
  background: linear-gradient(to right, #8B4513 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.4s ease-in-out;
 
  border: 2px solid #8B4513;
 
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.read1:hover {
  background-position: left bottom;
  color: white;
  border-color: #D2691E;
}
 
/* linking page */
 
.button-readmore {
  display: inline-block;
  padding: 10px 20px;
  background-color: #8B4513;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}
 
.button-readmore:hover {
  background-color: #D2691E;
}
 
.content2img {
  /* margin-top: -30px; */
}
/* second container media query */
@media screen and (max-width: 767px) {
  .container-np2 {
    display: flex;
    height: auto;
    overflow-x: hidden;
  }
  .container21 {
    /* grid-template-rows: 1fr ; */
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    /* border-radius: 2px solid black; */
    /* gap: 50px; */
    /* margin-top: 50px; */
    /* margin-left: -20px; */
  }
  .maindivs {
    overflow-x: hidden;
    width: 100vw;
  }
  .left-text {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
    text-align: justify;
  }
  .right-text {
    width: 50%;
    height: 100%;
    display: flex;
  }
  .content2img {
    width: 250px;
    height: 250px;
    justify-content: center;
    margin-left: 10%;
 
    /* margin-left: -50px; */
  }
  .readmore {
    margin-top: 100px;
    /* margin-left: -7%; */
    width: 100%;
  }
}
 
@media screen and (max-width: 1025px) {
  .container-np2 {
    display: flex;
    height: auto;
    overflow-x: hidden;
  }
  .container21 {
    /* grid-template-rows: 1fr ; */
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    /* border-radius: 2px solid black; */
    /* gap: 50px; */
    /* margin-top: 50px; */
    margin-left: -20px;
  }
  .maindivs {
    overflow-x: hidden;
    width: 100vw;
  }
  .left-text {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
    text-align: justify;
  }
  .right-text {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .content2img {
    width: 250px;
    height: 250px;
    justify-content: center;
 
    margin: auto;
    /* margin-left: -50px; */
  }
  .readmore {
    margin: 50px;
 
    width: 100%;
  }
}
 
@media screen and (max-width: 767px) {
  .component-paras {
    display: none;
  }
}
 
/* section3 */
.section3 {
  width: 100%;
  padding: 4rem 0;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cont2-title.title {
  font-size: 2.5rem;
  color: #8B4513;
  font-family: "Roboto Slab", serif;
  font-weight: 800;
  text-align: center;
  margin: 0;
  padding: 0 1rem;
  width: 100%;
  position: relative;
  margin-top: -20px;
  margin-bottom: 4rem;
}

.cont2-title.title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: linear-gradient(to right, transparent, #8B4513, transparent);
}

.section3parts-manageservice {
  width: 100%;
  max-width: 1400px;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 0 2rem;
}

@media screen and (max-width: 768px) {
  .cont2-title.title {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 3rem;
  }

  .section3parts-manageservice {
    padding: 0 1rem;
    gap: 1.5rem;
    margin-top: 1rem;
  }
}
 
.section3parts {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 750px;
  display: grid;
  margin-bottom: 30px;
  overflow: hidden;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.section3parts6{
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 750px;
  display: grid;
  margin-bottom: 30px;
  overflow: hidden;
  grid-template-columns: 1fr 1fr 1fr ;
}
.section3partsss {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 750px;
  display: grid;
  margin-bottom: 30px;
  overflow: hidden;
  grid-template-columns: 1fr 1fr 1fr ;
}
 
 
 
 
.section3partss {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns in the grid */
   /* gap between grid items */
}
 
.adm-readmore {
  text-decoration: none;
  color: orangered;
}
/* .adm-readmore:hover{
 
  color: white;
} */
 
.content-np {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  color: #f8f8f8;
  font-weight: 600;
 
 
}
.content-nps {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  letter-spacing: 1px;
  color: #f8f8f8;
  font-weight: 600;
 
 
}
 
.item31 {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: center;
  overflow: hidden;
  background-image: url("/public/Images/coms2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #357296;
}
.item316 {
  grid-column-start: 1;
  justify-content: center;
  overflow: hidden;
  background-image: url("/public/Images/coms2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #357296;
}


.item31s {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: center;
  overflow: hidden;
  background-image: url("/public/Images/coms2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #357296;
  width: 700px;
}
 
.item32-conent {
  top: 0;
  width: 100%;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}
.item32 {
  height: 100%;
  width: 100%;
  background-color: brown;
  overflow-y: hidden;
 
}
.item31-conent-img {
  height: 40%;
  width: 95%;
  background-image: url("/public/Images/img2");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.item32-headings {
  color: white;
  font-size: 15px;
  font-family: "Roboto Slab", serif;
}
.item32-heading {
  color: #000;
  font-size: 18px;
  font-family: "Roboto Slab", serif;
}
.item33 {
 
  background-color: #963596;
 
}
.item333 {
width: 600px;
  background-color: #963596;
 
}
.item34 {
 
  background-color: #dfdfdf;
  height: 90%;
  margin-top: -13.5%;
  color: #000;
 
}
.item35 {
  background-color: rgb(235, 184, 73);
  height: 90%;
  margin-top: -13.5%;
 
}
.item36 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(250, 114, 90);
  height: 90%;
  margin-top: -13.5%;
  /* background-image: url("image2.jpg"); */
}
.item37 {
  /* background-image: url("image4.jpg"); */
  background-color: #df9926;
  height: 90%;
  margin-top: -13.5%;
  padding-left: 15px;
  background-image: url("/public//Images/coms1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000;
}
.item37s {
  /* background-image: url("image4.jpg"); */
  background-color: #df9926;
  height: 90%;
  margin-top: -7.5%;
  padding-left: 15px;
  background-image: url("/public//Images/coms1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000;
}
 
.item35,
.item36,
.item37s {
  border: none;
}
 
 
@media only screen and (min-width: 768px) {
  .section3partss {
    margin-right: 5%;
    grid-template-columns: repeat(1, 1fr); /* Two columns for larger screens */
  }
}
 
.section3partss {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  grid-template-rows: repeat(2, auto); /* Two rows with auto height */
   /* Gap between grid items */
}
 
.content-np {
  padding: 10px; /* Add padding to each item */
 
}
 
.grid-containerq {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-left: 10px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns with equal width */
  grid-template-rows: auto auto; /* 2 rows */
  /* spacing between grid items */
}
/* Initial styles for grid items */
.grid-item-1,
.grid-item-11,
.grid-item-12 {
 
  padding: 20px;
  height: 300px; /* set height */
  width: 385px; /* set width */
}
 
/* Hover effect */
.grid-item-1:hover,
.grid-item-11:hover,
.grid-item-12:hover {
  transform: scale(1.05); /* Increase size on hover */
  transition: transform 0.3s ease; /* Smooth transition */
}
 
 
.grid-item-11 {
 
  background-image: url("/public/Images/coms2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 
}
.grid-item-12 {
 
  background-image: url("/public//Images/coms1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 
}
/* Media query for smaller screens (phones) */
@media only screen and (max-width: 600px) {
  .grid-containerq {
    grid-template-columns: 1fr; /* Single column layout for phones */
    margin: 0 10px; /* Add margin on left and right sides */
  }
 
}
 
/* Media query for tablets (768px) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .grid-containerq {
    grid-template-columns: repeat(1, 1fr); /* Two columns layout for tablets */
    margin: 0 20px; /* Add margin on left and right sides */
  }
}
 
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .grid-containerq {
    grid-template-columns: repeat(2, 1fr); /* Two columns layout for tablets */
    margin: 0 20px; /* Add margin on left and right sides */
  }
}
 
/* Media query for desktops (1024px and above) */
@media only screen and (min-width: 1024px) {
  .grid-containerq {
    grid-template-columns: repeat(3, 1fr); /* Three columns layout for desktops */
    margin: 0 auto; /* Center the grid container horizontally */
    max-width: 1200px; /* Limit the maximum width of the grid container */
    padding: 0 20px; /* Add padding on left and right sides */
  }
}
 
/* Add hover effect to each section */
.content-np:hover {
  transform: scale(1.05); /* Increase size on hover */
  transition: transform 0.3s ease; /* Add smooth transition */
}
 
/* Optionally, you can change other properties on hover */
.content-np:hover {
  /* Add any additional styling you want on hover */
}
.item3texts {
  color: #000;
}
.item3text {
  color: #000;
  margin-bottom: 30px;
}
/* If you want to change text color on hover */
.content-np:hover .item3text {
  color: white /* Your hover color */;
}
 
/* If you want to change background color on hover */
 
@media only screen and (max-width: 1012px) {
  /* .section3 {
    height:auto;
    width: 100%;
  } */
  /* section 5 cards */
 
  /* section 5 media-query */
  @media only screen and (min-width: 350px) and(max-width: 821px) {
    .section5 {
      height: auto;
      width: 100vw;
    }
    .section3parts-manageservice {
      grid-template-columns: repeat(2, 1fr);
      height: auto;
    }
  }
  @media only screen and (min-width: 822px) and (max-width: 998px) {
    .section5 {
      height: auto;
      width: 100vw;
    }
  }
  /* section4 */
  /* Carousel.css */
 
  .section4,
  .sliders {
    height: 100vh;
    width: 100vw;
 
    overflow-x: hidden;
  }
  .cont2-title {
    /* border:2px solid #df9926; */
    height: 20%;
    margin: 20px;
  }
  .section3parts-manageservice {
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
 
    /* margin-top: 100px; */
    margin-top: 10px;
    width: 100%;
    /* margin-left: 20px; */
    text-align: justify;
    justify-content: center;
    align-items: center;
  }
  .section3parts {
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
 
    /* margin-top: 100px; */
    margin-top: 10px;
    width: 100%;
    /* margin-left: 20px; */
   
    justify-content: center;
    align-items: center;
  }

  .section3parts6 {
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;

    /* margin-top: 100px; */
    margin-top: 10px;
    width: 100%;
    /* margin-left: 20px; */
    
    justify-content: center;
    align-items: center;
  }


  .section3partsss {
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
 
    /* margin-top: 100px; */
    margin-top: 10px;
    width: 100%;
    /* margin-left: 20px; */
   
    justify-content: center;
    align-items: center;
  }
  .item31 {
  }
  .content-np {
    height: 350px;
    /* font-size: 30px; */
    margin-top: 5px;
    justify-content: center;
    padding: 10px;
  }
  .item3text {
    font-size: 20px;
    margin-top: 2px;
  }
  .item32-heading {
    color: #000;
    font-size: 30px;
  }
  .item32-headings {
    color: white;
    font-size: 30px;
  }
  .aligned-paragraph {
    text-align: justify;
    margin-top: 10px;
    margin-left: 18px;
    margin-right: 20px;
    font-size: 16px;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
}
 
@media only screen and (min-width: 722px) and (max-width: 1025px) {
  .section3parts {
    display: grid;
    width: 85%;
    height: auto;
    grid-template-columns: 1fr 1fr;
  }

  .section3parts6 {
    display: grid;
    width: 85%;
    height: auto;
    grid-template-columns: 1fr 1fr;
  }

  
  .section3partsss {
    display: grid;
    width: 85%;
    height: auto;
    grid-template-columns: 1fr 1fr;
  }
 
  .section3parts-manageservice {
    display: grid;
    width: 85%;
    height: auto;
    grid-template-columns: 1fr 1fr;
  }
  .section3 {
    height: 100%;
  }
  .item32-heading {
    color: #000;
    font-size: 30px;
  }
  .item32-headings {
    color: white;
    font-size: 30px;
  }
  .aligned-paragraph {
    text-align: justify;
    margin-top: 10px;
    margin-left: 18px;
    margin-right: 20px;
    font-size: 16px;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
}
 
.container2s {
}
 
.aligned-paragraph {
  text-align: left;
  margin-top: 10px;
  margin-left: 18px;
  height: 55%;
  margin-right: 20px;
  font-size: 16px;
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 500;
 
}
 
.readmores {
  border-color: black;
  margin-bottom: -100px;
}
 
.readmores button {
  width: 180px;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: center;
  /* display: block; */
  font-size: 15px;
  background: linear-gradient(to right, rgb(29, 12, 94) 50%, white 50%);
  /* background-size: 200% 100%; */
  background-position: right bottom;
  transition: all 0.5s ease-out;
  /* margin: 60px; */
  border: 2px solid rgb(20, 20, 20);
  /* margin-top: -100px; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.readmores button:hover {
  background-position: left bottom;
  color: rgb(255, 255, 255);
  border: #fcfafa;
}
 
/* Section 5 */
.section5 {
  margin-top: 7px;
  width: 100vw;
  padding: 5rem 2rem;
  background: linear-gradient(135deg, #8B4513, #D2691E);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section5heading {
  text-align: center;
  margin-bottom: 4rem;
  color: white;
}

.section5heading h1 {
  font-size: 3rem;
  font-weight: 700;
  font-family: "Roboto Slab", serif;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.section5heading h1::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: white;
  border-radius: 2px;
}

.section5cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.case-study-item {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
}

.case-study-item:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.case-study-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.case-study-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
}

.case-study-item:hover .case-study-image img {
  transform: scale(1.1);
}

.case-study-content {
  padding: 2rem;
  color: white;
}

.case-study-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: white;
}

.case-study-description {
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
}

.case-study-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  text-decoration: none;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.case-study-link:hover {
  background: white;
  color: #8B4513;
  transform: translateX(5px);
}

.case-study-link svg {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.case-study-link:hover svg {
  transform: translateX(3px);
}

.case-study-tags {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.case-study-tag {
  padding: 0.25rem 0.75rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
}

@media (max-width: 768px) {
  .section5 {
    padding: 3rem 1rem;
  }

  .section5heading h1 {
    font-size: 2.5rem;
  }

  .section5cards {
    grid-template-columns: 1fr;
  }

  .case-study-content {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .section5heading h1 {
    font-size: 2rem;
  }

  .case-study-title {
    font-size: 1.25rem;
  }
}

/* section4 */
/* Carousel.css */
 
.section4,
.sliders {
  margin-top: 3%;
  height: auto;
  width: 100vw;
  overflow: hidden;
}
.slick-slider {
  position: relative;
  width: 84%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 9%;
  /* margin-right: 10%; */
  margin-top: 5%;
  overflow: hidden;
}
 
/* Slide item */
.slider-item {
  width: 450px;
  overflow-y: hidden;
  position: relative;
  height: 600px; /* Adjust as needed */
  background-size: cover;
  background-position: center;
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
  color: #875353; /* Text color */
  /* Adjust padding as needed */
  /* box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);  */
  /* Optional: Add box shadow for a card-like effect */
}
 
.modal-container {
  position: relative;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-dialog {
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
  height: 80vh;
  overflow-y: auto;
  position: relative;
  animation: modalSlideIn 0.3s ease-out;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #8B4513;
  z-index: 1;
}

.modal-close:hover {
  color: #D2691E;
}

.modal-content-wrapper {
  padding: 20px;
  height: 100%;
}

.modal-np {
  font-size: 2rem;
  font-weight: 600;
  color: #8B4513;
  margin-bottom: 2rem;
  text-align: center;
  font-family: "Roboto Slab", serif;
}

.modal-content-sections {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding: 20px;
}

.modal-section {
  padding: 1.5rem;
  background: rgba(139, 69, 19, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(139, 69, 19, 0.1);
  transition: all 0.3s ease;
}

.modal-section:hover {
  background: rgba(139, 69, 19, 0.08);
  transform: translateX(5px);
}

.modal-section strong {
  display: block;
  color: #8B4513;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-family: "Roboto Slab", serif;
}

.modal-section p {
  color: #4A5568;
  line-height: 1.6;
  font-size: 1rem;
  margin: 0;
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .modal-dialog {
    width: 95%;
    padding: 20px;
    height: 90vh;
  }

  .modal-content-sections {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 10px;
  }

  .modal-np {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .modal-section {
    padding: 1rem;
  }

  .modal-section strong {
    font-size: 1.1rem;
  }
}

.slider-item .h2-np {
  font-size: 30px;
  /* margin-bottom: 15px; */
  margin-top: 20%;
  padding-top: 5px;
  width: 100%;
 
  justify-content: center;
  text-align: center;
  height: 115px !important;
}
 
.slider-item .p-np {
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 50px;
  justify-content: center;
  text-align: center;
}
 
/* Arrow buttons */
.nav-button-slider {
  /* position: absolute; */
 
  background-color: transparent;
  color: #4e4b4b;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  position: absolute;
  height: 600px;
  width: 70px;
  z-index: 111;
  overflow: hidden;
  /* background-color: #963596; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5%; */
  font-size: 50px;
}
 
.prev {
  left: 2%;
  /* top: 50%; */
}
 
.next {
  right: 2%;
}
 
.slidecontent {
  color: black;
  width: 100%;
  /* margin-top: 30%; */
  justify-content: center;
  overflow: hidden;
  height: 60%;
  /* background-color: #963596; */
}
.slidecontents {
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
 
  width: 90%;
  height: 80%;
  background: rgba(139, 69, 19, 0.05) url("/public/Images/images.jpg");
  background-size: cover; /* Adjust background size to your preference */
  background-repeat: no-repeat;
  background-position: center center;
  /* background-image: url("/public/Images/slide1.jpg"); */
}
.slideheading {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
/* carosole modal */
 
/* Modal Content */
 
.modal {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.modal[style*="display: block"] {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  position: relative;
  padding: 30px;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
}

#close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s ease;
}

#close:hover {
  color: #000;
}

.closeicon {
  font-size: 24px;
}

.section6 {
  height: auto;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow: hidden;
  margin-bottom: 5%;
 
  /* font-size: 18px;
  font-weight: 300; */
}
.section6title {
  margin-left: 10%;
  display: flex;
 
  flex-wrap: wrap;
}
._6textheading {
  font-size: 40px;
  color: #000;
  font-weight: 900;
  line-height: 40px;
  font-family: "Roboto Slab", serif;
}
 
.section6title-np,
.section6title-np2 {
  display: flex;
  width: 100%;
}
 
.section6title-np2 {
  font-size: 30px;
  font-weight: 300;
  margin-top: 10px;
  line-height: 40px;
  color: #7e7e7e !important;
  box-sizing: border-box;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-left: 2%;
}
.section6title-np1 {
  font-size: 70px;
  font-weight: 500;
  font-family: "Roboto Slab", serif;
  line-height: 70px;
}
.section6row {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  /* background-color: brown; */
  height: auto;
  width: 100%;
  margin-left: 15%;
 
  display: flex;
  flex-wrap: wrap;
  border-left: none;
}
/* colom section in rows */
.section666 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
 
  height: auto;
  justify-content: center;
  border-left: none;
 
  border-top: 1px solid black;
}
.section666col {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
 
  justify-content: center;
  /* align-items: center; */
}
._6text {
}
.a-np {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-decoration: none;
  color: black;
  font-size: 20px;
  color: #000;
  font-weight: 400;
  line-height: 25px;
  text-align: -webkit-match-parent;
}
li {
  list-style-type: none;
  font-size: 18px;
 
  /* line-height: 26px; */
 
  display: list-item;
  /* text-align: -webkit-match-parent; */
}
 
/* Media query for devices with a maximum width of 768 pixels */
@media only screen and (max-width: 768px) {
  .section666 {
    grid-template-columns: 1fr;
  }
  .section6row {
    margin-left: 5px;
    width: 100%;
  }
  .section6title-np {
    margin-left: 5px;
  }
  .carousel-caption {
    height: auto !important;
  }
  .section3parts-manageservice {
    height: auto;
  }
  .section3parts {
    height: auto;
  }

  .section3parts6 {
    height: auto;
  }


  .section3partsss {
    height: auto;
  }
 
  .slick-slider {
    margin-left: 10.2%;
  }
  /* .prev{
    margin-left: 0;
  } */
}
.component-1 {
  font-size: 50px;
}
 
@media only screen and (max-width: 685px) {
  .prev {
    left: 1%;
  }
  .next {
    right: 1%;
  }
  /* .slick-slider {
    margin-left: auto;
    margin-right: auto;
  } */
}
 
@media only screen and (max-width: 500px) {
  /* .nav-button-slider{
    width: auto;
  }
  .next{
    right: 3%;
  }
  .prev{
    left:0.1%;
  } */
 
  .slidecontents {
    width: 95%;
  }
}
 
@media only screen and (max-width: 866px) {
  .carousel-caption {
    height: auto !important;
  }
}
 
@media only screen and (max-width: 406px) {
  .component-1 {
    font-size: 40px;
  }
}
 
@media only screen and (max-width: 306px) {
  .component-1 {
    font-size: 30px;
  }
}
 
.main-cont {
  background-color: #D2691E;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding-top: 2rem;
}
 
.cardcomp {
  padding-left: 6rem;
  padding-right: 6rem;
}
 
.cardcomp .row-ca {
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}
 
.cardcomp .row .col-ca {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
 
.cardhead {
  text-align: center !important;
  margin-bottom: 6.5rem;
}
.head-t {
  font-family: "Roboto Slab", serif;
  font-size: 3rem;
  line-height: 6rem;
  margin-bottom: 1rem;
  color: white;
}
 
.t-color {
  color: #eee;
  margin-bottom: 0rem;
}
 
.card-wrapper {
  position: relative;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 23rem;
  height: 10rem;
  perspective: 1000px;
  transition: all 1.2s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.main-card,
.hover-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: all 1.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.main-card {
  background: rgba(255, 255, 255, 0.95);
  z-index: 2;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transform: rotateY(0deg);
  overflow: hidden;
  position: relative;
  gap: 1rem;
}

.main-card img {
  width: 4.5rem;
  height: 4.5rem;
  margin: 0;
  order: 2;
  margin-left: auto;
  padding: 0.5rem;
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
}

.card-content1 {
  text-align: left;
  transition: all 0.3s ease;
  order: 1;
  flex: 1;
  padding-right: 1rem;
}

.card-content1 h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #8B4513;
  transition: all 0.3s ease;
  background: linear-gradient(90deg, #8B4513, #D2691E);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
}

.hover-card {
  background: linear-gradient(135deg, rgba(139, 69, 19, 0.95), rgba(210, 105, 30, 0.95));
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.75rem;
  opacity: 0;
  backdrop-filter: blur(12px);
}

.card-wrapper:hover {
  transform: translateY(-15px) scale(1.02);
  transition: transform 1.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.card-wrapper:hover .main-card::before {
  left: 100%;
}

.card-wrapper:hover .main-card {
  transform: rotateY(-180deg);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  transition: all 1.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.card-wrapper:hover .main-card img {
  transform: scale(0.9) rotate(-5deg);
  filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.2));
}

.card-wrapper:hover .hover-card {
  transform: rotateY(0);
  opacity: 1;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  transition: all 1.2s cubic-bezier(0.4, 0, 0.2, 1);
  height: auto;
  min-height: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.hover-content {
  color: white;
  text-align: left;
  opacity: 0;
  transform: translateY(20px);
  transition: all 1.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 1rem;
}

.card-wrapper:hover .hover-content {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.4s;
}

.hover-content h2 {
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  position: relative;
  display: inline-block;
  text-align: left;
}

.hover-content h2::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 50px;
  height: 2px;
  background: linear-gradient(90deg, rgba(255,255,255,1), rgba(255,255,255,0.2));
  transform: translateX(0);
}

.hover-content p {
  font-size: 0.85rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
  text-align: left;
}

/* Adjust scrollbar styling for hover card */
.hover-card::-webkit-scrollbar {
  width: 4px;
}

.hover-card::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
}

.hover-card::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
}

/* Media query adjustments */
@media only screen and (max-width: 686px) {
  .hover-content h2 {
    font-size: 0.9rem;
  }

  .hover-content p {
    font-size: 0.75rem;
    line-height: 1.4;
  }
  
  .card-wrapper:hover .hover-card {
    max-height: 250px;
  }
}

.card-container {
  padding-left: 5rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}
.row-ck {
  width: 100%;
  height: auto;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
 
.col-ck {
  flex: 0 0 0%; /* Adjust the width of each column */
  width: 100%;
  /* Adjust the maximum width of each column */
  margin: 1rem;
}
 
.innercon {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
 
.card-content1 {
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline;
}
 
.card-content1 h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
 
.card-content1 p {
  font-size: 1rem;
  color: #666;
}
.col-ck {
  width: 100%;
}
 
@media only screen and (max-width: 1360px) {
  .card-wrapper {
    width: 21rem;
  }
}
@media only screen and (max-width: 1098px) {
  .card-container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
 
/* @media only screen and (max-width: 755px){
  .col-ck {
      margin: 0.7rem;
  }
  .col-ck {
    margin: 0.8rem;
  }
 
  .card-wrapper {
    width: 19rem;
    height: 9rem;
  }
 
  .card-wrapper:hover .hover-card {
   
    transform: scale(1.2 , 1.8);
   
  }
 
  .hover-content p {
    font-size: 0.7rem;
    color: #fff
  }
 
  .main-card {
   
    font-size: 0.7rem;
   
  }
} */
 
/* @media only screen and (max-width: 686px){
  .col-ck {
    margin: 0.8rem;
  }
 
  .card-wrapper {
    width: 20rem;
    height: 9rem;
  }
}
 
@media only screen and (max-width: 755px) {
  .col-ck {
    margin: 0.7rem;
  }
 
  .card-wrapper {
    width: 18rem;
    height: 8rem;
  }
} */
 
@media only screen and (max-width: 746px) {
  .col-ck {
    margin: 0.6rem;
  }
 
  .card-wrapper {
    width: 16rem;
    height: 7rem;
  }
}
 
@media only screen and (max-width: 686px) {
  .col-ck {
    margin: 0.5rem;
  }
 
  .card-content1 h2 {
    font-size: 1rem;
  }
 
  .card-wrapper {
    width: 14rem;
    height: 6rem;
  }
 
  .hover-content h2 {
    font-size: 0.8rem;
  }
 
  .hover-content p {
    font-size: 0.6rem;
  }
}
 
@media only screen and (max-width: 545px) {
  .col-ck {
    margin: 0.5rem;
  }
 
  .card-wrapper {
    width: 23rem;
    height: 10rem;
  }
}
 
@media only screen and (max-width: 430px) {
  .col-ck {
    margin: 0.5rem;
  }
 
  .card-wrapper {
    width: 17rem;
    height: 8rem;
  }
}
 
@media only screen and (max-width: 342px) {
  .col-ck {
    margin: 0.4rem;
  }
 
  .card-wrapper {
    width: 13rem;
    height: 6rem;
  }
}
 
@media screen and (max-width: 350px) {
  .section3parts-manageservice {
    grid-template-columns: 1fr;
    height: auto;
  }
}
/* section7 */
.section7 {
  padding: 6rem 0;
  background: linear-gradient(135deg, #fff 0%, #f8f9fa 100%);
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

/* Topic Section */
.topic-section {
  text-align: center;
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.main-heading {
  font-size: 3rem;
  font-weight: 700;
  color: #8B4513;
  margin-bottom: 1.5rem;
  font-family: "Roboto Slab", serif;
  position: relative;
  display: inline-block;
}

.main-heading::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: linear-gradient(90deg, #8B4513, #D2691E);
  border-radius: 2px;
}

.main-description {
  font-size: 1.2rem;
  color: #666;
  max-width: 800px;
  margin: 2rem auto 0;
  line-height: 1.6;
}

/* Slider Section */
.slider-section {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 60px;
  position: relative;
  width: 100%;
}

/* Slider Card Styles */
.slider-card {
  padding: 1.5rem;
  height: 300px;
  margin: 0.5rem;
}

.slider-card-content {
  background: white;
  height: 100%;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(139, 69, 19, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 1.5rem;
  background: rgba(139, 69, 19, 0.05);
  border-radius: 50%;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.slider-card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #8B4513;
  margin-bottom: 1rem;
  font-family: "Roboto Slab", serif;
}

.slider-card p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin: 0;
}

/* Slider Navigation */
.slick-arrow.custom {
  width: 44px;
  height: 44px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(139, 69, 19, 0.1);
  transition: all 0.3s ease;
  z-index: 1;
}

.slick-arrow.custom:hover {
  background: #8B4513;
  color: white;
}

.slick-prev.custom {
  left: -22px;
}

.slick-next.custom {
  right: -22px;
}

/* Dots Navigation */
.slick-dots {
  bottom: -40px;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #8B4513;
  opacity: 0.3;
}
 
.slick-dots li.slick-active button:before {
  opacity: 1;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .slider-card {
    height: 280px;
    padding: 1rem;
  }

  .slider-card-content {
    padding: 1.5rem;
  }

  .slider-card h3 {
    font-size: 1.25rem;
  }

  .slider-card p {
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .section7 {
    padding: 4rem 0;
    gap: 3rem;
  }

  .main-heading {
    font-size: 2.5rem;
  }

  .main-description {
    font-size: 1.1rem;
  }

  .slider-section {
    padding: 0 20px;
  }

  .slider-card {
    height: 260px;
  }

  .card-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
  }

  .slider-card h3 {
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
  }

  .slider-card p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .section7 {
    padding: 3rem 0;
    gap: 2rem;
  }

  .main-heading {
    font-size: 2rem;
  }

  .main-description {
    font-size: 1rem;
  }

  .slider-card {
    height: 240px;
  }

  .slider-card-content {
    padding: 1.25rem;
  }

  .slick-arrow.custom {
    width: 36px;
    height: 36px;
  }
}


