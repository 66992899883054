/* Base styles */
:root {
  /* Color palette extracted from logo */
  --primary: #8B4513;      /* Orange */
  --secondary: #4A4A4A;    /* Dark Gray */
  --accent: #FF8533;       /* Light Orange */
  --text-dark: #2D2D2D;    /* Almost Black */
  --text-light: #FFFFFF;   /* White */
  --bg-light: #F9F9F9;     /* Light Gray */
  --bg-gradient: linear-gradient(135deg, var(--primary) 0%, var(--accent) 100%);
}

/* Base styles */
.homepage {
  overflow-x: hidden;
  color: var(--text-dark);
}

section {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4rem 0;
  position: relative;
  overflow: hidden;
}

section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.03;
  background-image: radial-gradient(var(--secondary) 1px, transparent 1px);
  background-size: 20px 20px;
  z-index: 0;
}

/* Typography */
.section-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: inherit;
}

/* Add specific styles for services section */
.text-center.section-title {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  margin-bottom: 0.5rem !important;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 80px;
  height: 4px;
  background: var(--primary);
}

.section-description {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 3rem;
  max-width: 800px;
  color: var(--secondary);
  font-weight: 600;  /* Added font weight */
}

@media (max-width: 768px) {
  .section-title {
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
  }
  
  .text-center.section-title {
    font-size: 0.8rem !important;
    margin-bottom: 0.3rem !important;
  }

  .section-description {
    font-size: 1rem;
    font-weight: 600;  /* Maintained font weight for mobile */
  }
}

/* Hero Section */
.hero-section {
  min-height: 100vh;
  padding: 4rem 2rem; /* Reverted back to original */
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center; /* Reverted back to center */
}

.animated-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;  /* Disable all interactions */
}

.wave {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 107, 0, 0.1), rgba(255, 133, 51, 0.05));
  opacity: 0.3;
  animation: wave 20s infinite linear;
  transform-origin: center 60%;
  pointer-events: none;  /* Disable all interactions */
  user-select: none;  /* Prevent selection */
}

.wave:nth-child(2) {
  animation-delay: -7s;
  opacity: 0.3;
}

@keyframes wave {
  0% {
    transform: rotate(0deg) scale(1.5);
  }
  50% {
    transform: rotate(180deg) scale(2);
  }
  100% {
    transform: rotate(360deg) scale(1.5);
  }
}

.floating-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.shape {
  position: absolute;
  background: linear-gradient(135deg, rgba(255, 107, 0, 0.1), rgba(255, 133, 51, 0.05));
  border-radius: 50%;
  animation: float 20s infinite ease-in-out;
}

.shape:nth-child(1) {
  width: 300px;
  height: 300px;
  top: -150px;
  right: -150px;
  animation-delay: -5s;
}

.shape:nth-child(2) {
  width: 200px;
  height: 200px;
  bottom: -100px;
  left: -100px;
  animation-delay: -10s;
}

.shape:nth-child(3) {
  width: 150px;
  height: 150px;
  top: 50%;
  right: 10%;
  animation-delay: -15s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-30px) rotate(180deg);
  }
}

.hero-content-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  padding-top: 2rem; /* Restored padding */
}

.hero-text {
  padding: 0;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.4s ease-out;
  position: relative;
  z-index: 2;
  background: transparent;
}

.hero-title {
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  text-align: center;
}

.hero-title .title-line {
  display: block;
  margin-bottom: 0.3rem;
}

.hero-title .accent {
  color: #8B4513;
  position: relative;
  display: inline-block;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: clamp(1.8rem, 3.5vw, 2.2rem);
  }
}

.title-line {
  display: block;
  text-align: left;
  width: 100%;
  padding-left: 0;
  transition: transform 0.3s ease-out;
  color: #000;
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 1.1;
}

.title-line.accent {
  color: var(--primary);
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 1.1;
  margin: 0.3rem 0;
  text-align: left;
  padding-left: 0;
  transition: all 0.3s ease-out;
  text-shadow: 2px 2px 4px rgba(139, 69, 19, 0.2);
}

.hero-subtitle {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #2D2D2D;
  margin: 1rem 0 1.5rem 0;
  max-width: 600px;
  text-align: left;
  padding: 1rem;
  transition: all 0.3s ease-out;
  opacity: 1;
  font-weight: 500;
  position: relative;
  z-index: 5;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 700px) {
  .hero-subtitle {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 1.2rem auto;
    line-height: 1.6;
    max-width: 90%;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}

.hero-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 220px));
  gap: 1.2rem;
  margin-top: 1.5rem;
  transition: all 0.4s ease-out;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.hero-card {
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 1.2rem; /* Reduced padding */
  border: 1px solid rgba(139, 69, 19, 0.1);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(139, 69, 19, 0.05);
  max-width: 220px; /* Reduced max-width */
  text-align: center;
  min-height: 180px; /* Set minimum height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hero-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(139, 69, 19, 0.1), rgba(139, 69, 19, 0.05));
  opacity: 0;
  transition: opacity 0.4s ease;
}

.hero-card:hover {
  transform: translateY(-5px); /* Reduced lift */
  border-color: var(--primary);
  box-shadow: 0 8px 25px rgba(139, 69, 19, 0.12);
}

.hero-card:hover::before {
  opacity: 1;
}

.card-icon {
  width: 2rem; /* Smaller icon */
  height: 2rem;
  color: var(--primary);
  margin-bottom: 0.75rem;
  transition: all 0.4s ease;
  padding: 0.35rem;
  background: rgba(139, 69, 19, 0.08);
  border-radius: 8px;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}

.hero-card:hover .card-icon {
  transform: scale(1.1) rotate(5deg);
  background: var(--primary);
  color: white;
}

.card-title {
  font-size: 1.1rem; /* Smaller font size */
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--text-dark);
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  text-align: center;
}

.card-description {
  font-size: 0.85rem; /* Smaller font size */
  color: var(--secondary);
  line-height: 1.4;
  transition: all 0.3s ease;
  opacity: 0.9;
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 0; /* Remove bottom margin */
}

.hero-card:hover .card-title {
  color: var(--primary);
  transform: translateX(5px);
}

.hero-card:hover .card-description {
  transform: translateX(5px);
}

.hero-visual {
  position: relative;
  width: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  perspective: 2500px;
  transform-style: preserve-3d;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(-80px); /* Added transform to move just the globe up */
}

/* Remove the decorative corner elements */
.hero-visual::before,
.hero-visual::after {
  display: none;
}

.hero-image {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-180px);
  pointer-events: none;  /* Disable click interactions */
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 30px;
  display: block;
  aspect-ratio: 1/1;
  transform: scale(1.4);
  user-select: none;  /* Prevent selection */
}

/* Frame styling */
.hero-image::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 30px;
  padding: 2px;
  background: linear-gradient(45deg, var(--primary), var(--accent));
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

/* Decorative elements */
.hero-image::after {
  content: '';
  position: absolute;
  inset: -2px;
  background: linear-gradient(45deg, var(--primary), transparent, var(--accent));
  border-radius: 32px;
  z-index: 0;
  opacity: 0.5;
  filter: blur(10px);
  transition: opacity 0.5s ease;
}

/* Hover effects */
.hero-image:hover {
  transform: translateY(-180px);  /* Keep it static */
}

.hero-image:hover::after {
  opacity: 0.5;  /* Keep static opacity */
}

.hero-image:hover img {
  transform: scale(1.4);  /* Keep static scale */
}

/* Floating animation */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Decorative Corner Elements */
.hero-visual::before,
.hero-visual::after {
  content: '';
  position: absolute;
  width: 150px;
  height: 150px;
  opacity: 0.3;
  transition: all 0.5s ease;
}

.hero-visual::before {
  top: 10%;
  left: -30px;
  border-top: 3px solid var(--primary);
  border-left: 3px solid var(--primary);
  border-radius: 30px 0 0 0;
}

.hero-visual::after {
  bottom: 10%;
  right: -30px;
  border-bottom: 3px solid var(--accent);
  border-right: 3px solid var(--accent);
  border-radius: 0 0 30px 0;
}

.hero-visual:hover::before,
.hero-visual:hover::after {
  opacity: 0.6;
  width: 180px;
  height: 180px;
}

@media (max-width: 1200px) {
  .hero-content-wrapper {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 1rem;
  }

  .hero-text, .hero-title, .title-line, .hero-subtitle {
    text-align: left;
  }

  .hero-subtitle {
    margin: 0 0 2rem 0;
  }

  .hero-cards {
    max-width: 800px;
    margin: 2rem auto 0;
  }

  .hero-visual {
    min-height: 500px;
    padding: 1rem;
  }

  .hero-image {
    min-height: 400px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 1rem;
  }

  .hero-title, .title-line, .title-line.accent {
    font-size: 3.2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
    margin: 0.75rem 0 1rem 0;
  
  }

  .hero-cards {
    grid-template-columns: repeat(auto-fit, minmax(180px, 200px));
    gap: 1rem;
  }

  .hero-card {
    padding: 1rem;
    min-height: 160px;
  }

  .hero-visual {
    min-height: 400px;
    padding: 0.5rem;
  }

  .hero-image {
    min-height: 400px;
  }
}

@media (max-width: 700px) {
  .hero-section {
    min-height: auto;
    padding: 6rem 1rem 2rem 1rem;
  }

  .hero-content-wrapper {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0;
    text-align: center;
  }

  .hero-text {
    text-align: center;
    align-items: center;
    margin-top: 1rem;
    padding: 0;
  }

  .hero-title, 
  .title-line,
  .title-line.accent {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 800;
    letter-spacing: -0.5px;
    width: 100%;
    padding: 0;
  }

  .title-line.accent {
    font-size: 2rem;
    font-weight: 900;
    margin: 0.3rem 0;
    text-align: center;
  }

  .hero-subtitle {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 1.2rem auto;
    line-height: 1.6;
    max-width: 90%;
    padding: 1rem;
    color: #000000 !important;
  }

  .hero-cards {
    grid-template-columns: 1fr;
    max-width: 100%;
    gap: 1rem;
    margin: 2rem auto 0;
    padding: 0 1rem;
  }

  .hero-card {
    max-width: 100%;
    margin: 0 auto;
  }
}

/* Feature Cards */
.feature-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 107, 0, 0.1);
  height: 100%;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(255, 107, 0, 0.1);
  border-color: var(--primary);
}

.feature-icon {
  width: 48px;
  height: 48px;
  color: var(--primary);
  margin-bottom: 1.5rem;
  padding: 10px;
  background: rgba(255, 107, 0, 0.1);
  border-radius: 12px;
  transition: all 0.3s ease;
}

.feature-card:hover .feature-icon {
  background: var(--primary);
  color: white;
  transform: rotate(10deg);
}

.feature-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.feature-description {
  color: var(--secondary);
  font-size: 1rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .feature-card {
    padding: 1.5rem;
  }

  .feature-icon {
    width: 36px;
    height: 36px;
    margin-bottom: 1rem;
  }

  .feature-title {
    font-size: 1.1rem;
  }
}

/* Buttons */
.btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn-primary {
  background: var(--primary);
  color: white;
  border: none;
}

.btn-primary:hover {
  background: var(--accent);
  transform: translateY(-1px);
}

/* Creative Navigation */
.nav-dots {
  position: fixed;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.nav-dots.visible {
  opacity: 1;
  visibility: visible;
}

.nav-dot {
  position: relative;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.95);
  border: 2px solid rgba(139, 69, 19, 0.2);
  border-radius: 15px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 15px rgba(139, 69, 19, 0.1);
}

.nav-dot svg {
  width: 24px;
  height: 24px;
  color: #8B4513;
  transition: all 0.3s ease;
}

.nav-dot::after {
  content: attr(aria-label);
  position: absolute;
  left: calc(100% + 1rem);
  top: 50%;
  transform: translateY(-50%);
  background: #8B4513;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  font-size: 0.875rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  font-weight: 500;
  letter-spacing: 1px;
  box-shadow: 0 4px 15px rgba(139, 69, 19, 0.1);
}

.nav-dot:hover {
  transform: translateX(5px);
  background: #8B4513;
  border-color: rgba(255, 255, 255, 0.3);
}

.nav-dot:hover svg {
  color: white;
  transform: scale(1.1);
}

.nav-dot:hover::after {
  opacity: 1;
  visibility: visible;
  transform: translateY(-50%) translateX(10px);
}

.nav-dot.active {
  background: #8B4513;
  border-color: rgba(255, 255, 255, 0.3);
  transform: translateX(5px);
  box-shadow: 0 8px 25px rgba(139, 69, 19, 0.2);
}

.nav-dot.active svg {
  color: white;
  transform: scale(1.1);
}

.nav-dot.active::after {
  background: #8B4513;
  font-weight: 600;
  box-shadow: 0 8px 25px rgba(139, 69, 19, 0.2);
}

/* Hover effect for the entire navigation */
.nav-dots:hover .nav-dot:not(:hover):not(.active) {
  opacity: 0.7;
}

@media (max-width: 768px) {
  .nav-dots {
    left: 1rem;
    gap: 1rem;
  }
  
  .nav-dot {
    width: 40px;
    height: 40px;
  }
  
  .nav-dot svg {
    width: 20px;
    height: 20px;
  }
  
  .nav-dot::after {
    display: none;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .section-title {
    font-size: 1.2rem;
    margin-bottom: 0.75rem;
  }
  
  .hero-title {
    font-size: 3rem;
  }
  
  .hero-subtitle {
    font-size: 1.25rem;
  }
  
  .nav-dots {
        display: none;
    }
}

/* Section-specific styles */
#vision, #mission, #goal, #approach, #innovation, #clients, #strategies {
  position: relative;
}

#vision { background: linear-gradient(135deg, #fff5eb 0%, #fff 100%); }
#mission { background: linear-gradient(135deg, #fff5eb 0%, #fff 100%); }
#goal { background: var(--bg-light); }
#approach { background: white; }
#innovation { background: linear-gradient(135deg, #fff5eb 0%, #fff 100%); }
#clients { background: white; }
#strategies { background: var(--bg-light); }

/* Image decorations */
.section-image {
      width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 12px;
  margin: 2rem 0;
  padding: 12px;
    position: relative;
  background: linear-gradient(135deg, var(--primary) 0%, var(--accent) 100%);
  animation: float 6s ease-in-out infinite;
}

.section-image::before {
  content: '';
  position: absolute;
  inset: 3px;
  background: white;
  border-radius: 10px;
  z-index: 0;
}

.section-image img {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Corner decorations */
.section-image::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid var(--primary);
  border-radius: 0 0 12px 0;
  right: -5px;
  bottom: -5px;
  border-left: none;
  border-top: none;
}

.section-image::before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid var(--accent);
  border-radius: 12px 0 0 0;
  left: -5px;
  top: -5px;
  border-right: none;
  border-bottom: none;
}

/* Success story image frame */
.story-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  position: relative;
  padding: 8px;
  background: linear-gradient(135deg, var(--primary) 0%, var(--accent) 100%);
}

.story-image::before {
  content: '';
  position: absolute;
  inset: 2px;
  background: white;
  z-index: 0;
}

.story-image img {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Hover effects */
.success-story-card:hover .story-image {
  transform: scale(1.02);
  transition: transform 0.3s ease;
}

.section-image:hover {
  transform: scale(1.02) translateY(-5px);
  box-shadow: 0 15px 30px rgba(255, 107, 0, 0.1);
  transition: all 0.3s ease;
}

/* Success Stories Section */
.success-story-card {
  background: white;
  border-radius: 12px;
    overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
}

.success-story-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

.success-story-card h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-dark);
  margin: 1.5rem;
}

.success-story-card p {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--secondary);
  margin: 0 1.5rem 1.5rem;
}

/* Technology Stack Section */
#tech-stack {
  background: linear-gradient(135deg, #fff5eb 0%, #fff 100%);
}

.tech-stack-grid {
  margin: 3rem 0;
}

/* Update section backgrounds */
#tech-stack { background: linear-gradient(135deg, #fff5eb 0%, #fff 100%); }
#success-stories { background: var(--bg-light); }

/* Add animation for section images */
.section-image {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Add hover effect for success story cards */
.success-story-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--bg-gradient);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.success-story-card:hover::after {
  transform: scaleX(1);
}

/* Social Media Icons */
.social-icons {
  position: fixed;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 100;
}

.social-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #1E1E1E;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transition: all 0.3s ease;
}

.social-icon:hover {
  transform: translateY(-2px);
  background: var(--primary);
}

.social-icon svg {
  width: 20px;
  height: 20px;
}

/* Learn More Button */
.learn-more {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--secondary);
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 2rem;
  transition: all 0.3s ease;
}

.learn-more:hover {
  color: var(--primary);
  gap: 1rem;
}

/* Mission Section Styles */
.mission-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
    overflow: hidden;
  }

.bg-grid-pattern {
  background-image: 
    linear-gradient(to right, rgba(255, 107, 0, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 107, 0, 0.1) 1px, transparent 1px);
  background-size: 24px 24px;
}

.mission-container .group {
  transform: translateY(0);
  will-change: transform;
}

.mission-container .group:hover {
  transform: translateY(-8px);
}

.mission-container .group::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at 50% 0%, rgba(255, 107, 0, 0.1), transparent 70%);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.mission-container .group:hover::before {
  opacity: 1;
}

/* Mission Card Icon Styles */
.mission-container .w-16 {
  position: relative;
  isolation: isolate;
}

.mission-container .w-16::after {
  content: '';
  position: absolute;
  inset: -2px;
  background: inherit;
  filter: blur(8px);
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: -1;
}

.mission-container .group:hover .w-16::after {
  opacity: 0.5;
}

/* Mission Text Styles */
.mission-container h2 {
  background-size: 200% auto;
  animation: shine 3s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .mission-container .grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 0 1rem;
  }
  
  .mission-container h2 {
    font-size: 2.5rem;
  }
}

/* Mission Section Styles */
.mission-section {
  min-height: 100vh;
  background: linear-gradient(135deg, #fff5eb 0%, #fff 100%);
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
}
 
/* Background Orbs */
.mission-section::before {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  background: radial-gradient(circle at center, rgba(255, 107, 0, 0.15), transparent 70%);
  top: -100px;
  right: -100px;
  border-radius: 50%;
  animation: float 10s ease-in-out infinite;
  z-index: 0;
}

.mission-section::after {
  content: '';
  position: absolute;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle at center, rgba(255, 133, 51, 0.1), transparent 70%);
  bottom: -50px;
  left: -50px;
  border-radius: 50%;
  animation: float 8s ease-in-out infinite reverse;
  z-index: 0;
}

/* Animated Pattern */
.mission-pattern {
  position: absolute;
  inset: 0;
  background-image: 
    linear-gradient(45deg, rgba(255, 107, 0, 0.05) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(255, 107, 0, 0.05) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(255, 107, 0, 0.05) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(255, 107, 0, 0.05) 75%);
  background-size: 40px 40px;
  background-position: 0 0, 0 20px, 20px -20px, -20px 0px;
  animation: patternMove 20s linear infinite;
  opacity: 0.5;
}

@keyframes patternMove {
  0% {
    background-position: 0 0, 0 20px, 20px -20px, -20px 0px;
  }
  100% {
    background-position: 40px 40px, 40px 60px, 60px 20px, 20px 40px;
  }
}

@keyframes float {
  0%, 100% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(30px, 30px) rotate(5deg);
  }
}

.mission-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.mission-header {
  text-align: center;
  margin-bottom: 3rem;
}

.mission-label {
  display: inline-block;
  padding: 0.5rem 1.5rem;
  background: rgba(255, 107, 0, 0.1);
  color: var(--primary);
  border-radius: 50px;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.mission-title {
  font-size: 3rem;
  font-weight: 700;
  background: linear-gradient(to right, var(--primary), var(--accent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.5rem;
}

.mission-description {
  font-size: 1.125rem;
  color: var(--secondary);
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.mission-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  padding: 1rem;
}

.mission-card {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  padding: 2rem;
  border: 1px solid rgba(255, 107, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.mission-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 30px rgba(255, 107, 0, 0.1);
  border-color: var(--primary);
}

.mission-icon-wrapper {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.mission-icon {
  width: 2rem;
  height: 2rem;
  color: white;
}

.mission-content h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-dark);
  margin-bottom: 0.75rem;
  transition: color 0.3s ease;
}

.mission-card:hover h3 {
  color: var(--primary);
}

.mission-content p {
  font-size: 1rem;
  color: var(--secondary);
  line-height: 1.6;
}

/* Background Pattern */
.mission-pattern {
  position: absolute;
  inset: 0;
  background-image: 
    linear-gradient(to right, rgba(255, 107, 0, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 107, 0, 0.1) 1px, transparent 1px);
  background-size: 24px 24px;
  opacity: 0.1;
}

@media (max-width: 768px) {
  .mission-grid {
    grid-template-columns: 1fr;
  }
  
  .mission-title {
    font-size: 2.5rem;
  }
  
  .mission-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
  }
  
  .mission-icon-wrapper {
    margin-bottom: 1rem;
  }
}

/* Goals Section Styles */
.goals-section {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #fff5eb 0%, #fff 100%);
  min-height: 100vh;
  position: relative;
    overflow: hidden;
}

.goals-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(255, 107, 0, 0.15) 0%, transparent 70%),
    radial-gradient(circle at 80% 80%, rgba(255, 133, 51, 0.1) 0%, transparent 70%),
    linear-gradient(45deg, rgba(255, 107, 0, 0.03) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(255, 107, 0, 0.03) 25%, transparent 25%);
  background-size: 40px 40px;
  animation: patternMove 20s linear infinite;
  pointer-events: none;
}

.goals-section::after {
  content: '';
  position: absolute;
  inset: 0;
  background-image: 
    linear-gradient(45deg, rgba(255, 107, 0, 0.03) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(255, 107, 0, 0.03) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(255, 107, 0, 0.03) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(255, 107, 0, 0.03) 75%);
  background-size: 40px 40px;
  background-position: 0 0, 0 20px, 20px -20px, -20px 0px;
  animation: patternMove 20s linear infinite;
  opacity: 0.5;
  pointer-events: none;
}

@keyframes patternMove {
  0% {
    background-position: 0 0, 0 20px, 20px -20px, -20px 0px;
  }
  100% {
    background-position: 40px 40px, 40px 60px, 60px 20px, 20px 40px;
  }
}

@media (max-width: 768px) {
  .goals-section {
    padding: 4rem 1rem;
  }
}

/* Linear Section Styles */
.linear-section {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #fff5eb 0%, #fff 100%);
  position: relative;
  overflow: hidden;
}

.linear-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(255, 107, 0, 0.15) 0%, transparent 70%),
    radial-gradient(circle at 80% 80%, rgba(255, 133, 51, 0.1) 0%, transparent 70%),
    linear-gradient(45deg, rgba(255, 107, 0, 0.03) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(255, 107, 0, 0.03) 25%, transparent 25%);
  background-size: 40px 40px;
  animation: patternMove 20s linear infinite;
  pointer-events: none;
}

.linear-section::after {
  content: '';
  position: absolute;
  inset: 0;
  background-image: 
    linear-gradient(45deg, rgba(255, 107, 0, 0.03) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(255, 107, 0, 0.03) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, rgba(255, 107, 0, 0.03) 75%),
    linear-gradient(-45deg, transparent 75%, rgba(255, 107, 0, 0.03) 75%);
  background-size: 40px 40px;
  background-position: 0 0, 0 20px, 20px -20px, -20px 0px;
  animation: patternMove 20s linear infinite;
  opacity: 0.5;
  pointer-events: none;
}

.text-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.linear-cards {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1000px;
  margin: 3rem auto 0;
  position: relative;
  z-index: 1;
}

.linear-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 107, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.linear-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: var(--primary);
  transform: scaleY(0);
  transition: transform 0.3s ease;
}

.linear-card:hover {
  transform: translateX(10px);
  border-color: var(--primary);
  box-shadow: 0 20px 40px rgba(255, 107, 0, 0.1);
  background: rgba(255, 255, 255, 0.95);
}

.linear-card:hover::before {
  transform: scaleY(1);
}

.linear-card-content {
  flex: 1;
  padding-right: 2rem;
}

.linear-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-dark);
  margin-bottom: 0.75rem;
  position: relative;
  display: inline-block;
}

.linear-card-title::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 40px;
  height: 2px;
  background: var(--primary);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  transform-origin: left;
}

.linear-card:hover .linear-card-title::after {
  transform: scaleX(1);
}

.linear-card-description {
  color: var(--secondary);
  font-size: 1rem;
  line-height: 1.6;
  max-width: 600px;
}

.linear-card-icon {
  width: 48px;
  height: 48px;
  color: var(--primary);
  margin-left: 2rem;
  transition: all 0.3s ease;
  padding: 10px;
  background: rgba(255, 107, 0, 0.1);
  border-radius: 12px;
}

.linear-card:hover .linear-card-icon {
  transform: translateX(-10px) rotate(10deg);
  background: var(--primary);
  color: white;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
}

.modal-overlay.active {
  opacity: 1;
  visibility: visible;
}

.modal-content {
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.95), rgba(255, 245, 235, 0.98));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 30px;
  padding: 3rem;
  max-width: 900px;
  width: 90%;
  max-height: 85vh;
  overflow-y: auto;
  position: relative;
  transform: scale(0.95) translateY(20px);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(255, 107, 0, 0.1);
  box-shadow: 
    0 30px 60px -12px rgba(0, 0, 0, 0.25),
    0 0 30px -12px rgba(255, 107, 0, 0.3);
}

.modal-overlay.active .modal-content {
  transform: scale(1) translateY(0);
  opacity: 1;
}

.modal-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: var(--primary);
  border: none;
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(255, 107, 0, 0.2);
}

.modal-close:hover {
  background: var(--accent);
  transform: rotate(90deg);
  box-shadow: 0 6px 16px rgba(255, 107, 0, 0.3);
}

.modal-header {
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
}

.modal-header::after {
  content: '';
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: var(--primary);
  border-radius: 2px;
}

.modal-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, var(--primary), var(--accent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.modal-subtitle {
  color: var(--secondary);
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
}

.modal-body {
  margin-top: 3rem;
}

.modal-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.modal-grid-item {
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.9), rgba(255, 245, 235, 0.95));
  backdrop-filter: blur(10px);
  padding: 2.5rem;
  border-radius: 20px;
  border: 1px solid rgba(255, 107, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.modal-grid-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, var(--primary), var(--accent));
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.modal-grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(255, 107, 0, 0.15);
  border-color: var(--primary);
}

.modal-grid-item:hover::before {
  transform: scaleX(1);
}

.modal-grid-item .linear-card-icon {
  width: 50px;
  height: 50px;
  padding: 12px;
  background: var(--primary);
  border-radius: 14px;
  color: white;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.modal-grid-item:hover .linear-card-icon {
  background: var(--accent);
  transform: rotate(10deg);
}

.modal-section-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--primary);
  margin-bottom: 1rem;
  position: relative;
}

.modal-section-content {
  color: var(--secondary);
  font-size: 1.1rem;
  line-height: 1.7;
}

/* Custom scrollbar for modal */
.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: rgba(255, 107, 0, 0.05);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: var(--accent);
}

.modal-content {
  padding: 2rem;
  width: 95%;
}

.modal-title {
  font-size: 2rem;
}

.modal-subtitle {
  font-size: 1.1rem;
}

.modal-grid {
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.modal-grid-item {
  padding: 2rem;
}

.modal-section-title {
  font-size: 1.3rem;
}

.modal-section-content {
  font-size: 1rem;
}

/* Tech & Success Section */
.tech-success-section {
  padding: 6rem 0;
  background: #f8f9fa;
  position: relative;
  overflow: hidden;
}

.tech-card {
  background: #fff;
  border-radius: 16px;
  padding: 2rem;
  height: 100%;
  transition: all 0.3s ease;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.tech-card:hover {
  transform: translateY(-8px);
}

.tech-icon-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 16px;
  background: linear-gradient(135deg, #A0522D, #D2691E);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.tech-icon {
  width: 30px;
  height: 30px;
  color: #fff;
}

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.tech-tag {
  background: rgba(160, 82, 45, 0.1);
  color: #A0522D;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  transition: background 0.3s ease;
}

.tech-tag:hover {
  background: rgba(160, 82, 45, 0.2);
}

.success-card {
  text-align: center;
  padding: 2rem;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.success-card:hover {
  transform: translateY(-5px);
}

.success-icon-wrapper {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: linear-gradient(135deg, #A0522D, #D2691E);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
}

.success-metric {
  font-size: 2.5rem;
  font-weight: 700;
  color: #A0522D;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .tech-success-section {
    padding: 4rem 0;
  }

  .tech-card {
    padding: 1.5rem;
  }

  .success-card {
    padding: 1.5rem;
  }

  .success-metric {
    font-size: 2rem;
  }
}

/* Contact/Journey Section */
.journey-section {
  min-height: auto;
  background: linear-gradient(135deg, #f5e6d3 0%, #fff 100%);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 4rem 2rem;
  margin-bottom: 0;
}

.journey-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(139, 69, 19, 0.15) 0%, transparent 70%),
    radial-gradient(circle at 80% 80%, rgba(160, 82, 45, 0.1) 0%, transparent 70%),
    linear-gradient(45deg, rgba(139, 69, 19, 0.03) 25%, transparent 25%),
    linear-gradient(-45deg, rgba(139, 69, 19, 0.03) 25%, transparent 25%);
  background-size: 40px 40px;
  animation: patternMove 20s linear infinite;
  pointer-events: none;
}

.journey-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 0;
}

.journey-title {
  font-size: 3rem;
  font-weight: 800;
  background: linear-gradient(135deg, #8B4513, #A0522D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  position: relative;
  line-height: 1.2;
}

.journey-title::after {
  content: '';
  position: absolute;
  bottom: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #8B4513, #A0522D);
  border-radius: 2px;
}

.journey-description {
  font-size: 1.2rem;
  color: var(--secondary);
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.6;
}

.journey-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.journey-card {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  border: 1px solid rgba(139, 69, 19, 0.1);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: left;
  position: relative;
  overflow: hidden;
}

.journey-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, #8B4513, #A0522D);
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: 0;
}

.journey-card:hover {
  transform: translateY(-8px);
  border-color: #8B4513;
  box-shadow: 0 20px 40px rgba(139, 69, 19, 0.1);
}

.journey-card:hover::before {
  opacity: 0.05;
}

.journey-card-content {
  position: relative;
  z-index: 1;
}

.journey-icon {
  color: #8B4513;
  width: 48px;
  height: 48px;
  transition: all 0.3s ease;
}

.journey-icon svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
  color: currentColor;
}

.journey-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #2D2D2D;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.journey-card:hover .journey-card-title {
  color: #8B4513;
}

.journey-card:hover .journey-icon {
  color: #fff;
  background: #8B4513;
  transform: rotate(10deg) scale(1.1);
  box-shadow: 0 4px 15px rgba(139, 69, 19, 0.1);
}

.journey-card-description {
  font-size: 1.1rem;
  color: var(--secondary);
  line-height: 1.6;
}

.journey-cta {
  margin: 1rem 0 0;
  text-align: center;
}

.journey-button {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  background: #8B4513;
  color: white;
  padding: 1.25rem 2.5rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.journey-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transform: translateX(-100%);
  transition: transform 0.6s ease;
}

.journey-button:hover {
  transform: translateY(-3px);
  background: #A0522D;
  box-shadow: 0 20px 40px rgba(139, 69, 19, 0.2);
}

.journey-button:hover::before {
  transform: translateX(100%);
}

.journey-button-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.journey-button:hover .journey-button-icon {
  transform: translateX(5px);
}

@media (max-width: 768px) {
  .journey-section {
    padding: 3rem 1rem;
  }

  .journey-title {
    font-size: 2.5rem;
  }

  .journey-description {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .journey-cards {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .journey-card {
    padding: 1.5rem;
  }

  .journey-button {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }
}

.gateway-card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  padding: 2rem;
  border: 2px solid rgba(139, 69, 19, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.gateway-card:hover {
  background: #8B4513;
  border-color: #fff;
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.gateway-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  color: #8B4513;
  padding: 10px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  transition: all 0.3s ease;
}

.gateway-card:hover .gateway-icon {
  transform: scale(1.1) rotate(10deg);
  color: #8B4513;
  background: #fff;
}

.gateway-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #2D2D2D;
  transition: color 0.3s ease;
}

.gateway-description {
  color: #666;
  line-height: 1.6;
  font-size: 15px;
  transition: color 0.3s ease;
}

.gateway-card:hover .gateway-title,
.gateway-card:hover .gateway-description {
  color: #fff;
}

/* Welcome Popup */
.welcome-popup-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.welcome-popup-overlay.active {
  opacity: 1;
  visibility: visible;
}

.welcome-popup {
  position: relative;
  width: 90%;
  max-width: 600px;
  background: linear-gradient(135deg, #fff, #f8f8f8);
  border-radius: 24px;
  padding: 3rem;
  transform: translateY(30px) scale(0.95);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid rgba(139, 69, 19, 0.1);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.welcome-popup.active {
  transform: translateY(0) scale(1);
  opacity: 1;
}

.welcome-popup::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(90deg, #8B4513, #A0522D);
}

.welcome-popup-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid rgba(139, 69, 19, 0.2);
  background: white;
  color: #8B4513;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(255, 107, 0, 0.2);
}

.welcome-popup-close:hover {
  background: #8B4513;
  color: white;
  transform: rotate(90deg);
}

.welcome-popup-title {
  font-size: 2.5rem;
  font-weight: 800;
  color: #8B4513;
  margin-bottom: 1rem;
  text-align: center;
  line-height: 1.2;
}

.welcome-popup-subtitle {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.welcome-popup-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background: linear-gradient(135deg, #8B4513, #A0522D);
  color: white;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 auto;
  text-decoration: none;
}

.welcome-popup-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(139, 69, 19, 0.2);
}

.welcome-popup-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.welcome-popup-button:hover .welcome-popup-icon {
  transform: translateX(5px);
}

.welcome-popup-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

@media (max-width: 768px) {
  .welcome-popup {
    padding: 2rem;
  }
  
  .welcome-popup-title {
    font-size: 2rem;
  }
  
  .welcome-popup-subtitle {
    font-size: 1rem;
  }
  
  .welcome-popup-button {
    width: 100%;
    justify-content: center;
  }
}

/* Welcome Popup */
.welcome-popup-content {
  position: relative;
  text-align: center;
}

.welcome-popup-icon-wrapper {
  width: 80px;
  height: 80px;
  margin: 0 auto 2rem;
  background: linear-gradient(135deg, #8B4513, #A0522D);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 25px rgba(139, 69, 19, 0.2);
}

.welcome-popup-feature-icon {
  width: 40px;
  height: 40px;
  color: white;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.welcome-popup-button {
  position: relative;
  overflow: hidden;
  transform: scale(1);
  transition: all 0.3s ease;
}

.welcome-popup-button:hover {
  transform: scale(1.05);
}

.welcome-popup-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transform: rotate(45deg);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  100% {
    transform: translateX(100%) rotate(45deg);
  }
}

.welcome-popup-close {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(139, 69, 19, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(139, 69, 19, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(139, 69, 19, 0);
  }
}

.gateway-section {
  padding: 6rem 0;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .gateway-section {
    padding: 4rem 0;
  }
}

/* Feature cards grid layout */
.gateway-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1rem;
}

/* Feature card styles */
.feature-card {
  height: 100%;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  border-radius: 16px;
  background: var(--card-bg);
  transition: transform 0.3s ease;
}

.feature-icon {
  width: 56px;
  height: 56px;
  margin-bottom: 1.5rem;
  color: #fff;
  transition: transform 0.3s ease;
}

.feature-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1rem;
}

.feature-description {
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .gateway-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .gateway-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .feature-card {
    min-height: 240px;
    padding: 1.5rem;
  }

  .feature-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 1rem;
  }

  .feature-title {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .feature-description {
    font-size: 0.9rem;
  }
}

@keyframes rotate {
  from {
    transform: rotateX(60deg) rotateY(0deg) rotateZ(0deg);
  }
  to {
    transform: rotateX(60deg) rotateY(360deg) rotateZ(15deg);
  }
}

@keyframes rotateReverse {
  from {
    transform: rotateX(-50deg) rotateY(360deg) rotateZ(-5deg);
  }
  to {
    transform: rotateX(-50deg) rotateY(0deg) rotateZ(10deg);
  }
}

.orbital-ring {
  position: absolute;
  width: 110%;
  height: 110%;
  border: 1.5px solid rgba(139, 69, 19, 0.25);
  border-radius: 50%;
  animation: rotate 30s linear infinite;
  transform-style: preserve-3d;
  transform-origin: center center;
  backface-visibility: visible;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 0 20px rgba(139, 69, 19, 0.08);
}

.orbital-ring-reverse {
  position: absolute;
  width: 95%;
  height: 95%;
  border: 1.5px solid rgba(139, 69, 19, 0.35);
  border-radius: 50%;
  animation: rotateReverse 25s linear infinite;
  transform-style: preserve-3d;
  transform-origin: center center;
  backface-visibility: visible;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 0 20px rgba(139, 69, 19, 0.08);
}

/* Add styles for dropdown menu text */
.dropdown-menu {
  font-size: 1rem;
}

.dropdown-menu a {
  font-size: 0.9rem !important;
  color: #4A5568;
  transition: color 0.3s ease;
}

.dropdown-menu .menu-title {
  font-size: 1.8rem !important;
  font-weight: 600;
  color: #8B4513;
  margin-bottom: 1rem;
}

.dropdown-menu .menu-item {
  font-size: 0.9rem !important;
  color: #4A5568;
  transition: all 0.3s ease;
}

.dropdown-menu .menu-item:hover {
  color: #8B4513;
  transform: translateX(5px);
}

/* Specific styles for E-commerce and Security text */
.dropdown-menu .large-title {
  font-size: 1.8rem !important;
  font-weight: 600;
  color: #8B4513;
}

.dropdown-menu .small-text {
  font-size: 0.85rem !important;
  color: #4A5568;
  margin-top: 0.5rem;
}

.digital-gateway {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1200px;
}

.gateway-ring {
  position: absolute;
  border-radius: 50%;
  border: 2px solid rgba(139, 69, 19, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
  animation: rotateRing 30s linear infinite;
}

.outer-ring {
  width: 400px;
  height: 400px;
  animation-direction: normal;
}

.middle-ring {
  width: 300px;
  height: 300px;
  animation-direction: reverse;
}

.service-node {
  position: absolute;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 32px rgba(139, 69, 19, 0.15);
  transition: all 0.3s ease;
  cursor: pointer;
}

.service-node:hover {
  transform: scale(1.1) translateZ(20px);
  box-shadow: 0 12px 40px rgba(139, 69, 19, 0.25);
}

.node-icon {
  width: 32px;
  height: 32px;
  color: #8B4513;
  margin-bottom: 8px;
}

.service-node span {
  font-size: 0.8rem;
  font-weight: 600;
  color: #8B4513;
}

.node1 { transform: translate(200px, 0) rotateZ(0deg); }
.node2 { transform: translate(-100px, 173px) rotateZ(120deg); }
.node3 { transform: translate(-100px, -173px) rotateZ(240deg); }
.node4 { transform: translate(150px, 0) rotateZ(0deg); }
.node5 { transform: translate(-75px, 130px) rotateZ(120deg); }
.node6 { transform: translate(-75px, -130px) rotateZ(240deg); }

.gateway-core {
  position: relative;
  width: 160px;
  height: 160px;
  background: linear-gradient(135deg, #8B4513, #A0522D);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 50px rgba(139, 69, 19, 0.3);
  z-index: 10;
}

.core-content {
  text-align: center;
  color: white;
  z-index: 2;
}

.core-icon {
  width: 48px;
  height: 48px;
  margin: 0 auto 10px;
}

.core-icon .icon {
  width: 100%;
  height: 100%;
  color: white;
}

.core-content h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}

.core-content p {
  font-size: 0.8rem;
  opacity: 0.9;
  margin: 5px 0 0;
}

.core-rings {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.ring {
  position: absolute;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  animation: pulseRing 2s infinite;
}

.ring1 { width: 100%; height: 100%; animation-delay: 0s; }
.ring2 { width: 80%; height: 80%; animation-delay: 0.3s; }
.ring3 { width: 60%; height: 60%; animation-delay: 0.6s; }

.connection-paths {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.path {
  position: absolute;
  width: 120px;
  height: 2px;
  background: linear-gradient(90deg, rgba(139, 69, 19, 0.1), rgba(139, 69, 19, 0.3));
}

.particle {
  position: absolute;
  width: 6px;
  height: 6px;
  background: rgba(139, 69, 19, 0.6);
  border-radius: 50%;
  animation: moveParticle 2s infinite;
}

.path1 { top: 45%; left: 35%; transform: rotate(0deg); }
.path2 { top: 35%; right: 35%; transform: rotate(-60deg); }
.path3 { bottom: 35%; right: 35%; transform: rotate(60deg); }
.path4 { bottom: 45%; left: 35%; transform: rotate(180deg); }
.path5 { top: 50%; left: 40%; transform: rotate(-30deg); }
.path6 { top: 50%; right: 40%; transform: rotate(30deg); }

@keyframes rotateRing {
  from { transform: rotateY(0deg) rotateX(30deg); }
  to { transform: rotateY(360deg) rotateX(30deg); }
}

@keyframes pulseRing {
  0% { transform: scale(1); opacity: 0.2; }
  50% { transform: scale(1.1); opacity: 0.3; }
  100% { transform: scale(1); opacity: 0.2; }
}

@keyframes moveParticle {
  0% { left: 0; opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { left: 100%; opacity: 0; }
}

@media (max-width: 768px) {
  .digital-gateway {
    transform: scale(0.7);
  }
  
  .outer-ring {
    width: 300px;
    height: 300px;
  }
  
  .middle-ring {
    width: 220px;
    height: 220px;
  }
  
  .service-node {
    width: 60px;
    height: 60px;
  }
  
  .node-icon {
    width: 24px;
    height: 24px;
  }
  
  .service-node span {
    font-size: 0.7rem;
  }
  
  .gateway-core {
    width: 120px;
    height: 120px;
  }
}

.tech-image-container {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(139, 69, 19, 0.15);
  background: #fff;
  border: 2px solid rgba(139, 69, 19, 0.1);
}

.tech-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
}

.tech-image-container:hover .tech-image {
  transform: scale(1.05);
}

.tech-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(139, 69, 19, 0.1), rgba(139, 69, 19, 0.05));
  pointer-events: none;
}

.tech-dots {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 100px;
  height: 100px;
  background-image: radial-gradient(circle, var(--primary) 1px, transparent 2px);
  background-size: 10px 10px;
  opacity: 0.3;
}

.tech-line {
  position: absolute;
  bottom: 30px;
  left: 20px;
  width: 150px;
  height: 2px;
  background: linear-gradient(90deg, var(--primary), transparent);
  opacity: 0.5;
}

@media (max-width: 768px) {
  .tech-image-container {
    height: 400px;
  }
  
  .tech-dots {
    width: 60px;
    height: 60px;
  }
  
  .tech-line {
    width: 100px;
  }
}

