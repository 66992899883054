.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
}

.modal-overlay.active {
  opacity: 1;
  visibility: visible;
}

.modal-content {
  background: linear-gradient(145deg, #ffffff, #f5f5f5);
  padding: 40px;
  border-radius: 20px;
  width: 95%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  transform: translateY(30px) scale(0.9);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  box-shadow: 
    0 20px 50px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1);
}

.modal-overlay.active .modal-content {
  transform: translateY(0) scale(1);
  opacity: 1;
}

.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  font-size: 24px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  color: #333;
  z-index: 2;
}

.modal-close:hover {
  background-color: rgba(139, 69, 19, 0.2);
  transform: rotate(90deg);
  color: #8B4513;
}

.modal-header {
  margin-bottom: 45px;
  padding-right: 40px;
  position: relative;
  text-align: center;
}

.modal-header::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 120px;
  background: radial-gradient(circle, rgba(139, 69, 19, 0.1) 0%, rgba(139, 69, 19, 0) 70%);
  border-radius: 50%;
  z-index: 0;
}

.modal-header::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #8B4513, #A0522D);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(139, 69, 19, 0.2);
}

.modal-title {
  font-size: 38px;
  font-weight: 800;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  background: linear-gradient(135deg, #1a1a1a 0%, #333 50%, #666 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  animation: titleFade 0.6s ease-out forwards;
}

.modal-subtitle {
  color: #666;
  font-size: 18px;
  line-height: 1.7;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  animation: subtitleFade 0.6s ease-out 0.2s forwards;
  opacity: 0;
  transform: translateY(10px);
}

@keyframes titleFade {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes subtitleFade {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add this new style for the section title */
.section-title {
  font-size: 42px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
  background: linear-gradient(135deg, #8B4513, #A0522D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  padding-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: -1px;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(90deg, #8B4513, #A0522D);
  border-radius: 2px;
}

.section-description {
  font-size: 18px;
  color: #666;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.7;
}

.modal-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.modal-grid-item {
  background: white;
  padding: 25px;
  border-radius: 16px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 
    0 4px 20px rgba(0, 0, 0, 0.05),
    0 0 0 1px rgba(255, 255, 255, 0.5) inset;
  position: relative;
  overflow: hidden;
}

.modal-grid-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #8B4513, #A0522D);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.modal-grid-item:hover {
  transform: translateY(-8px);
  box-shadow: 
    0 12px 30px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(255, 255, 255, 0.7) inset;
}

.modal-grid-item:hover::before {
  opacity: 1;
}

.linear-card-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  color: #8B4513;
  padding: 10px;
  background: rgba(139, 69, 19, 0.1);
  border-radius: 12px;
  transition: all 0.3s ease;
}

.modal-grid-item:hover .linear-card-icon {
  transform: scale(1.1);
  background: rgba(139, 69, 19, 0.15);
}

.modal-grid-item h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #1a1a1a;
}

.modal-grid-item p {
  color: #666;
  line-height: 1.6;
  font-size: 15px;
}

.welcome-popup-icon-wrapper {
  width: 80px;
  height: 80px;
  background: #A0522D;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
  position: relative;
}

.welcome-popup-feature-icon {
  width: 40px !important;
  height: 40px !important;
  color: white;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .modal-content {
    padding: 25px;
    width: 100%;
    margin: 10px;
    max-height: 85vh;
  }

  .modal-header {
    margin-bottom: 25px;
  }

  .modal-title {
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 0;
  }

  .modal-subtitle {
    display: none;
  }

  .section-description {
    display: none;
  }

  .modal-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 20px;
  }

  .modal-grid-item {
    padding: 20px;
  }

  .modal-grid-item h3 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .modal-grid-item p {
    font-size: 14px;
    line-height: 1.4;
  }

  .linear-card-icon {
    width: 36px;
    height: 36px;
    padding: 8px;
  }

  .modal-close {
    top: 15px;
    right: 15px;
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .modal-content {
    padding: 20px;
  }

  .modal-header {
    margin-bottom: 15px;
  }

  .modal-title {
    font-size: 22px;
  }

  .modal-grid-item {
    padding: 15px;
  }

  .modal-grid-item h3 {
    font-size: 17px;
  }

  .modal-grid-item p {
    font-size: 13px;
  }

  .linear-card-icon {
    width: 32px;
    height: 32px;
    padding: 7px;
  }
} 