/* blogcontent.css */
.blog-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-cover-image {
  width: 100%;
  height: auto;
  max-width: 100vw;
  /* Ensures image doesn't exceed viewport width */
  object-fit: cover;
  /* Maintain aspect ratio while covering */
}

.blog-content {
  max-width: 90vw;
  margin: 0 auto;
}

.blog-contents {

  max-width: 65vw;
  background-color: #F4F9FF;
  padding: 2rem;
}




.blog-content-col1 {
  width: 100%;
  /* Adjust as needed */
  max-width: 1200px;
  /* Example maximum width for responsiveness */
  margin-left: 10%;
}

.blog-content {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 50px;
}

.blog-content h3 {
  font-family: system-ui;
}

.blog-content h2 {
  font-size: 50px;
  font-family: "tungstenw05-medium", "Oswald", sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}

.blog-content p {
  font-family: system-ui;
  font-weight: 200;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.likes-comments {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;



}

.likes {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 50%;



}

.comments {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.8rem;
  padding: 2px;

}

.likes-data {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;


}

.related-links {
  height: auto;
  min-width: 200px;
  padding: 10px;
  /* margin-top: 11rem; */
}

.comment-form {
  margin-top: 20px;

}

.comment-form-user-details {
  display: flex;
  gap: 20px;
}

.comment-form input {
  max-width: 50%;
  max-height: 7vh;
  margin-top: 15px;
}

textarea {
  max-height: 14vh;
}

.comment-form input,
.comment-form label,
textarea {

  margin-bottom: 10px;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.comment-form label {
  border: none;
}

.form-group-captcha {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  max-width: 50%;
  
}

.comment-form button {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100px;
}

.comment-form input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

.comment-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.comment-form button:hover {
  background-color: #0056b3;
}

.comments-section {
  margin-top: 50px;

  /* padding: 10px; */

}

.comment-body {

  margin-top: 10px;
  padding: 5px;
  background-color: white;
  

}

.comment-header {
  display: flex;
  align-items: center;
  /* padding: 2px; */
  gap: 0.5rem;

}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

}

.username {
  margin-top: 10px;
  font-weight: bold;
  text-transform: capitalize;
  color: #4d4d4d;
  font-family: 'Myriad Pro Semibold';

}

.comment-content {
  margin-top: 10px;
  padding-left: 2px;
}

.moderation-note {
  color: #888;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.comment-text {
  color: #4d4d4d;
  font-weight: 600;

}

@media (max-width: 600px) {

  .comment-form input,
  .comment-form label,
  .comment-form button {
    font-size: 14px;
  }
}



.comment-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.related-links {

  /* margin-top: 40px; */
  /* margin-right:  50px; */
  width: 100%;
  max-width: 1000px;
  padding: 2px;





}

.related-links h3 {
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 4rem;
  text-transform: uppercase;

}

.related-links .categories {

  margin-bottom: 5px;
  margin-left: 4rem;
}

.related-links a {
  color: #007bff;
  text-decoration: none;
  /* font-weight: 100; */
  width: 100%;

}

.related-links a:hover {
  text-decoration: underline;
}

.related-links li {
  list-style: none;
  display: flex;
  align-items: center;
}

.related-links li svg {
  margin-right: 5px;
}

@media (max-width: 872px) {
  .comment-form-user-details {
    flex-direction: column;
    gap: 0px;
    max-width: 100%;

  }

  .comment-form-user-details input {
    max-width: 100%;
  }
}
/* @media (max-width: 550px) {
  .form-group-captcha {
    transform: scale(0.95); 
  }
} */
@media (max-width: 468px) {
  .form-group-captcha {
    transform: scale(0.90); 
  }
  /* .agree-checkbox{
    font-size: 5px;
    background-color: blue;
    
  } */
}

@media (max-width: 768px) {
  .blog-content{
    flex-direction: column;
    
  }
  .blog-contents{
    max-width: 100%;
    padding: 1rem;
  }
  
  .related-links h3{
    margin-left: 1rem;
  }
  .related-links .categories {
    margin-left: 1rem;
  }
  .social-share{
    display: none;
  }


  }


@media (max-width: 468px) {
  .likes-comments {
    flex-direction: column;
    align-items: center;


  }

  .likes {
    flex-direction: row;
    width: 100%;
    margin-left: 12px;
  }

  /* .form-group-captcha {
    width: 100%;
    transform: scale(0.75);
    
    transform-origin: 0 0;
    
    
  } */
}


/* --- */