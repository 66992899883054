/* Adjustments for mobile view */
@media (max-width: 768px) {
  /* Contact container adjustments */
  .contact-container {
    padding: 10px; /* Add padding to the contact container */
  }

  .contact-container h1 {
    font-size: 30px; /* Reduce font size of the main heading */
    margin-top: 30px; /* Adjust top margin of the main heading */
    margin-bottom: 30px; /* Adjust bottom margin of the main heading */
  }

  /* Grid container adjustments */
  .grid-container {
    grid-template-columns: 1fr; /* Single column layout for mobile */
    gap: 10px; /* Reduce the gap between grid items */
  }

  .grid-item {
    padding: 10px; /* Adjust padding for grid items */
    text-align: center; /* Center-align text within grid items */
  }
  
  .grid-item progress {
    display: none; /* Hide progress elements within grid items */
  }

  /* Form container adjustments */
  .form-container {
    grid-template-columns: 1fr; /* Single column layout for form container */
    padding: 10px; /* Add padding to the form container */
  }

  /* Location section adjustments */
  .Locations {
    height: auto; /* Auto height for mobile view */
  }

  .Location-name {
    font-size: 24px; /* Adjust font size of location names */
    margin-top: 20px; /* Adjust top margin of location names */
    margin-bottom: 20px; /* Adjust bottom margin of location names */
  }

  .address-text {
    font-size: 16px; /* Adjust font size of address text */
    padding-left: 10px; /* Add padding to the left of address text */
  }

  /* Contact section adjustments */
  .contact-section {
    display: flex; /* Use flexbox for contact section */
    flex-direction: column; /* Stack contact info vertically */
  }

  .contact-column {
    margin-bottom: 20px; /* Adjust margin between contact columns */
  }

  .contact-info p {
    font-size: 14px; /* Adjust font size of contact info */
  }
}

/* Adjustments for mobile view */
@media (max-width: 768px) {
  /* Container adjustments */
  .cont {
    padding: 10px;
  }

  .contact-container h1 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  /* Form container adjustments */
  .formaind {
    padding: 10px;
  }

  .input {
    width: 100%;
    margin-bottom: 10px;
  }

  .columnb {
    width: 100%;
  }

  /* Location section adjustments */
  .cantacth2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .conatacth4 {
    font-size: 16px;
  }

  .address1div-np {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .americas {
    margin-bottom: 20px;
  }

  /* Modal adjustments */
  .modal-content-m {
    max-width: 90%;
    margin: auto;
  }
}
