.card1 {

  padding-top: 40px !important;
   
     margin: 5px 5px 15px; /* Reduced margin at the top and bottom, slightly increased margin at the bottom */
   
     background-color: #fff;
   
     border: none;
   
   }
   
    
   

   .section-heading {
   
     text-align: left;
   
     font-size: 30px;
   
     line-height: 40px;
   
     visibility: visible;
   
     margin-top: 0;
   
     margin-bottom: 0.25rem; /* Reduced margin */
   
     display: block;
   
     padding: 0;
   
     font-size: 70px;
   
     font-weight: 500;
   
     font-family: 'Oswald', sans-serif;
   
     line-height: 0px;
   
     padding-bottom: 1px;
     padding-left: 20px;
   
   }
   
    
   
   .service-section {
   
    
     margin-top: 10px; /* Reduced margin */
     padding-top: 20px;
   }
   
    
   
   .service-row {
   
    display: grid;
    
    align-items: center;
    margin-bottom: 0; /* Remove the margin between Product rows */
    margin-top: -5px; /* Remove the margin between service rows */
   
   }
   
    
   
   .service-column {
   
     display: grid;
     
     padding: 5px; /* Reduced padding */
     margin: 0; /* Remove default margin */
   }
   
    
   
   .icon-and-text {
    /* padding-left: 5px; */
   
     /* display: flex; */
   
     align-items: center;
   
   }
   
    
   
   .heading-h6 {
    font-weight: 600;
    color: #000;
    font-size: 16px;
    margin: 10px 0;
    font-family: myriad-pro, Arial;
    line-height: 1.1;
    margin: 0; /* Remove margin to eliminate gap */
  }
  
   
  .service-row .service-column {
    margin: 0; /* Remove default margin */
  }
   .list-inline {
   
     list-style: none;
   
     padding: 0;
   
     margin: 0;
   display: grid;
   grid-template-columns: repeat(3,1fr);
     /* display: flex;
   
     flex-wrap: wrap; */
   
   }
   
   

  @media only screen and (max-width:990px) {

  
    .list-inline {
   
      
    grid-template-columns: repeat(2,1fr);
      
    
    }
    
  }

  @media only screen and (max-width:768px) {

  
    .list-inline {
   
      
    grid-template-columns: 1fr;
      
    
    }
    
  }

    
   
   .experience-list-item {
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 4px; /* Reduced margin between items */
    margin-bottom: 10px; /* Add margin bottom for spacing */
    width: calc(100%); /* Distribute items equally in 3 columns */
    box-sizing: border-box; /* Include padding and border in width calculation */
  }
  
  /* Clearfix for service columns */
  .service-column::after {
    content: "";
    display: table;
    clear: both;
  }
  
    
   
   /* Additional styles for links */
   
   .experience-list-item a,
   
   .icon-and-text a {
   
     text-decoration: none;
   
     color: #0e0f0f;
   
     transition: color 0.3s;
   
   }
   
    
   
   .experience-list-item a:hover,
   
   .icon-and-text a:hover {
   
     color: #fe432e; /* Change to blue color */
   
     text-decoration: none; /* Remove underline on hover */
   
   }
   
    
   
   .menu-seprator {
   
     height: 1px;
   
     background-color: #ccc;
   
     margin: 5px 0; /* Reduced margin */
   
   }
   
    
   
   .section-icon {
   
     font-size: 18px; /* Slightly reduced font size */
   
     margin-right: 8px; /* Slightly reduced margin */
   
     color: #000;
  
     padding-left: 15px;
   
   }
   
    
   
   /* Style for the text */
   
   .hear-txt-sub {
   
     font-weight: 600;
   
     font-family: Arial, Helvetica, sans-serif;
   
     color: #000;
   
     font-size: 13px; /* Slightly reduced font size */
   
     margin-top: 2px; /* Slightly reduced margin */
   
   }
   
    
   
   .service-column {
   
    /* flex: 1; */
    padding: 5px; /* Reduced padding */
    margin-right: 20px;
   
   }
   
    
   
   .service-column a {
   
     color: initial;
     font-size: 20px;
     font-family: system-ui; /* Set the initial color of the link */
   
     text-decoration: none; /* Remove the default underline */
   
     /* Transition for smooth color change */
   
     transition: color 0.3s ease-in-out;
   
   }
   
    
   
   .service-column a:hover {
   
     color: red; /* Change the color when hovering */
   
   }
   
    
   
   /* Your existing styles */
   
   @media (max-width: 768px) {
   
     .service-row {
   
       /* flex-direction: column;
   
       align-items: flex-start; Align items to the start of the column */
   
     }
   
    
   
     .service-column {
   
       /* flex: 1; */
   
       padding: 5px; /* Reduced padding */
   
       margin: 0;
   
     }
   
    
   
     .icon-and-text {
   
       margin-bottom: 5px; /* Reduced spacing between icon-and-text blocks */
   
     }
   
    
   
     .section-heading {
   
       font-size: 36px; /* Adjust the font size for smaller screens */
   
     }
   
    
   
     .experience-list-item,
   
     .icon-and-text a {
   
       font-size: 12px; /* Reduced font size for smaller screens */
   
     }
   
    
   
     .menu-seprator {
   
       margin: 5px 0; /* Reduced margin */
   
     }
   
  
   
     .section-icon {
   
       font-size: 14px; /* Slightly reduced font size */
   
     }
   
    
   
     .hear-txt-sub {
   
       font-size: 10px; /* Reduced font size for smaller screens */
   
     }
   
   }