.scroll-to-top-button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(135deg, #8B4513, #A0522D);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(139, 69, 19, 0.3);
  transition: all 0.3s ease;
  overflow: hidden;
}

.scroll-to-top-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transform: translateX(-100%);
  transition: transform 0.6s;
}

.scroll-to-top-button:hover::before {
  transform: translateX(100%);
}

@media (max-width: 768px) {
  .scroll-to-top-button {
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
} 