@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles below */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

:root {
  /* Color palette using brown */
  --primary: #8B4513;      /* Saddle Brown */
  --secondary: #4A4A4A;    /* Dark Gray */
  --accent: #A0522D;       /* Sienna Brown */
  --text-dark: #2D2D2D;    /* Almost Black */
  --text-light: #FFFFFF;   /* White */
  --bg-light: #F9F9F9;     /* Light Gray */
  --bg-gradient: linear-gradient(135deg, var(--primary) 0%, var(--accent) 100%);

  /* Font families */
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  
  /* Font weights */
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-extrabold: 800;
}

/* Global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #2D2D2D;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-weight: var(--font-bold);
  margin-bottom: 1rem;
}

p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
}

/* Heading sizes */
h1, .h1 {
  font-size: 3.5rem;
  line-height: 1.2;
}

h2, .h2 {
  font-size: 2.5rem;
  line-height: 1.3;
}

h3, .h3 {
  font-size: 2rem;
  line-height: 1.4;
}

h4, .h4 {
  font-size: 1.5rem;
  line-height: 1.4;
}

/* Hero section specific styles */
.hero-title, .title-line {
  font-weight: var(--font-extrabold);
  letter-spacing: -0.5px;
}

/* Navigation styles */
.menu-links {
  font-weight: var(--font-medium);
}

/* Responsive typography */
@media (max-width: 768px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
  
  h2, .h2 {
    font-size: 2rem;
  }
  
  h3, .h3 {
    font-size: 1.75rem;
  }
  
  h4, .h4 {
    font-size: 1.25rem;
  }
  
  p {
    font-size: 0.9375rem;
  }
}

/* Code styles */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Mission Section Styles */
.bg-grid-pattern {
  background-image: 
    linear-gradient(to right, rgba(139, 69, 19, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(139, 69, 19, 0.1) 1px, transparent 1px);
  background-size: 24px 24px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-primary);
}

/* Global styles */
.section-title {
  font-weight: var(--font-bold);
}

.section-description {
  font-weight: var(--font-regular);
}

/* Navigation styles */
.menu-links {
  font-weight: var(--font-medium);
}

/* Remove any font-family declarations from individual components */
h1, h2, h3, h4, h5, h6, p, span, a, button, input, textarea {
  font-family: inherit;
}

