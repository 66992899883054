.footercontainer {
    background: linear-gradient(135deg, #1e293b 0%, #0f172a 100%);
    position: relative;
    padding: 6rem 0 0 0 !important;
    color: #fff;
    overflow: hidden;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.container {
    padding: 0 4rem;
}

.footer-brand {
    position: relative;
    z-index: 1;
    padding-right: 3rem;
}

.brand-description {
    color: #94a3b8;
    font-size: 1rem;
    line-height: 1.7;
    margin: 1.5rem 0;
    max-width: 90%;
}

#footer-title {
    font-size: 1.15rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 1.8rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    position: relative;
    display: inline-block;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #8B4513;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin-bottom: 1.2rem;
}

#text {
    font-size: 1rem;
    text-decoration: none;
    color: #94a3b8;
    transition: all 0.3s ease;
    display: inline-block;
    position: relative;
    padding: 0.2rem 0;
}

#text:hover {
    color: #fff;
    transform: translateX(8px);
}

.img-footer {
    height: 50px;
    object-fit: contain;
    margin-bottom: 1.5rem;
}

.logo-white-text {
    filter: brightness(0) invert(1);
}

.logo-white-text path:first-child {
    filter: none;
}

.footer-cta {
    margin-top: 2.5rem;
}

.cta-button {
    display: inline-block;
    padding: 1rem 2.5rem;
    background: #8B4513;
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
    font-weight: 600;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.9rem;
}

.cta-button:hover {
    background: #a65c1c;
    color: #fff;
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(139, 69, 19, 0.2);
}

.contact-info {
    display: flex;
    flex-direction: column;
}

.contact-info li {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.contact-label {
    color: #8B4513;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.contact-info #text {
    color: #94a3b8;
    font-size: 1rem;
    line-height: 1.6;
    margin: 0;
}

.contact-info #text:hover {
    color: #fff;
    transform: none;
}

.address-text {
    color: #94a3b8;
    font-size: 1rem;
    line-height: 1.6;
}

.footer-bottom {
    background: rgba(0, 0, 0, 0.3);
    padding: 2rem 0;
    margin-top: 5rem;
    position: relative;
    z-index: 1;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.copyright-text {
    color: #94a3b8;
    font-size: 0.95rem;
    margin: 0;
}

.legal-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
}

.legal-links li a {
    color: #94a3b8;
    font-size: 0.95rem;
    text-decoration: none;
    transition: color 0.3s ease;
    position: relative;
}

.legal-links li a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: -4px;
    left: 0;
    background-color: #fff;
    transition: width 0.3s ease;
}

.legal-links li a:hover {
    color: #fff;
}

.legal-links li a:hover::after {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 0 2rem;
    }

    .footercontainer {
        padding: 4rem 0 0 0 !important;
    }
    
    .footer-brand {
        text-align: center;
        padding-right: 0;
    }
    
    .brand-description {
        max-width: 100%;
        text-align: center;
    }
    
    #footer-title {
        margin-top: 2.5rem;
        text-align: center;
        display: block;
        width: 100%;
    }
    
    .footer-links {
        text-align: center;
    }
    
    .img-footer {
        height: 45px;
        margin: 0 auto 1.5rem;
    }
    
    .contact-info {
        align-items: center;
        text-align: center;
    }
    
    .contact-info li {
        align-items: center;
    }
    
    .legal-links {
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;
        margin-top: 1.5rem;
    }
    
    .copyright-text {
        text-align: center;
        margin-bottom: 1rem;
    }

    .footer-cta {
        text-align: center;
    }

    .contact-info li {
        grid-template-columns: 1fr;
        gap: 0.3rem;
        text-align: center;
    }
    
    .contact-label {
        margin-bottom: 0.2rem;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .container {
        padding: 0 3rem;
    }
}
