.card-Product {

    padding-top: 40px;
     
       margin: 5px 5px 15px; /* Reduced margin at the top and bottom, slightly increased margin at the bottom */
     
       background-color: #fff;
     
       border: none;
     
     }
     
      
     
  
     .Product-heading {
     
       text-align: left;
     
       font-size: 40px;
     
       line-height: 40px;
     
       visibility: visible;
     
       margin-top: 0;
     
       margin-bottom: 0.25rem; /* Reduced margin */
     
       display: block;
     
      padding-left: 20px;
     
       font-size: 40px;
     
       font-weight: 500;
     
       font-family: 'Oswald', sans-serif;
     
       line-height: 0px;
     
       padding-bottom: 1px;
     
     }
     
      
     
     .Product-Product {
     
       margin-top: 10px; /* Reduced margin */
       padding-top: 20px;
     }
     
      
     
     /* Add this CSS code to your existing styles */
.Product-row {
    display: flex;
    align-items: center;
    margin-bottom: 0; /* Remove the margin between Product rows */
    margin-top: -5px; /* Adjust this value to move the sections slightly to the top */
  }
  
  /* Your existing styles */
  
      
     
     .Product-column {
     
       flex: 1;
     
       padding: 5px; /* Reduced padding */
       margin: 0; /* Remove default margin */
     }
     
      
     /* Add this CSS code to your existing styles */
.Product-row .Product-column {
    margin: 0; /* Remove default margin */
  }
  
  /* Your existing styles */
  
     .icon-and-text {
      /* padding-left: 5px; */
     
       display: flex;
     
       align-items: center;
     
     }
     
      
     
     .heading-h6 {
      font-weight: 600;
      color: #000;
      font-size: 16px;
      margin: 10px 0;
      font-family: myriad-pro, Arial;
      line-height: 1.1;
      margin: 0; /* Remove margin to eliminate gap */
    }
    
     
    .Product-row .Product-column .icon-and-text {
      margin-bottom: 5px; /* Adjust this margin to control the spacing */
    }
  
    .Product-row .Product-column:first-child .icon-and-text {
      margin-bottom: 10px; /* Adjust this value as needed */
    }
     
     .list-inline {
     
       list-style: none;
     
       padding: 0;
     
       margin: 0;
     
       display: flex;
     
       flex-wrap: wrap;
     
     }
     
      
     
     /* Add this CSS code to your existing styles */
/* Add this CSS code to your existing styles */
.Product-list-item {
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    margin-right: 4px; /* Reduced margin between items */
    margin-bottom: 10px; /* Add margin bottom for spacing */
    width: calc(33.33% - 10px); /* Distribute items equally in 3 columns */
    box-sizing: border-box; /* Include padding and border in width calculation */
    margin-top: 0; /* Remove top margin */
  }
  
  /* Your existing styles */
  
  
  /* Your existing styles */
  
    
    /* Clearfix for Product columns */
    .Product-column::after {
      content: "";
      display: table;
      clear: both;
    }
    
      
     
     /* Additional styles for links */
     
     .Product-list-item a,
     
     .icon-and-text a {
     
       text-decoration: none;
     
       color: #0e0f0f;
     
       transition: color 0.3s;
     
     }
     
      
     
     .Product-list-item a:hover,
     
     .icon-and-text a:hover {
     
       color: #fe432e; /* Change to blue color */
     
       text-decoration: none; /* Remove underline on hover */
     
     }
     
      
     
     .menu-seprator {
     
       height: 1px;
     
       background-color: #ccc;
     
       margin: 5px 0; /* Reduced margin */
     
     }
     
      
     
     .Product-icon {
     
       font-size: 18px; /* Slightly reduced font size */
     
       margin-right: 8px; /* Slightly reduced margin */
     
       color: #000;
    
       padding-left: 15px;
     
     }
     
      
     
     /* Style for the text */
     
     .hear-txt-sub {
     
       font-weight: 600;
     
       font-family: Arial, Helvetica, sans-serif;
     
       color: #000;
     
       font-size: 13px; /* Slightly reduced font size */
     
       margin-top: 2px; /* Slightly reduced margin */
     
     }
     
      
     
     .Product-column {
     
      flex: 1;
      padding: 5px; /* Reduced padding */
      margin-right: 20px;
     
     }
     
      
     
     .Product-column a {
     
       color: initial; /* Set the initial color of the link */
     
       text-decoration: none; /* Remove the default underline */
     
       /* Transition for smooth color change */
     
       transition: color 0.3s ease-in-out;
     
     }
     
      
     
     .Product-column a:hover {
     
       color: red; /* Change the color when hovering */
     
     }
     
      
     
     /* Your existing styles */
     
     @media (max-width: 768px) {
     
       .Product-row {
     
         flex-direction: column;
     
         align-items: flex-start; /* Align items to the start of the column */
     
       }
     
      
     
       .Product-column {
     
         flex: 1;
     
         padding: 5px; /* Reduced padding */
     
         margin: 0;
     
       }
     
      
     
       .icon-and-text {
     
         margin-bottom: 5px; /* Reduced spacing between icon-and-text blocks */
     
       }
     
      
     
       .Product-heading {
     
         font-size: 36px; /* Adjust the font size for smaller screens */
     
       }
     
      
     
       .Product-list-item,
     
       .icon-and-text a {
     
         font-size: 12px; /* Reduced font size for smaller screens */
     
       }
     
      
     
       .menu-seprator {
     
         margin: 5px 0; /* Reduced margin */
     
       }
     
    
     
       .Product-icon {
     
         font-size: 14px; /* Slightly reduced font size */
     
       }
     
      
     
       .hear-txt-sub {
     
         font-size: 10px; /* Reduced font size for smaller screens */
     
       }
     
     }