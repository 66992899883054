@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');



.p-2 {

    text-decoration: none;

    font-family: myriad-pro, Arial;

    color: #303030;

    font-size: 14px;

}

.p-2:hover {

    color: rgb(255, 72, 0);

    text-decoration: underline red;

}
.abc{
    text-decoration: none;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
}
#business-container{
    margin-top: 50px;

}

.submenu-h2-heading {

    margin-top: 60px;

    font-size: 70px;

    font-weight: 500;

    font-family: 'Oswald', sans-serif;
    /* font-family: myriad-pro, Arial; */

    line-height: 70px;

    padding: 0;

    line-height: 0px;

    padding-bottom: 0px;
    color: #3f3f3f;

}

