 .share-container {
     display: flex;
     flex-direction: row;
     margin-left: 0.8rem;
     gap: 1rem;
     width: 100%;
     padding: 2px;
     align-items: center;
     justify-content: center;
     



 }

 .share-text {
     margin-top: 10px;
     display: flex;
     text-align: center;
     font-weight: bolder;
 }

 .share-button {
     display: flex;
     align-items: center;
     justify-content: center;
     width: 36px;
     height: 36px;
     border-radius: 50%;
     border: 1px solid #000;
     /* margin-right: 10px; */
     cursor: pointer;
     padding: 5px;
     transition: color 0.4s ease, background-color 1s ease;

 }

 .share-button:last-child {
     margin-right: 0;
 }
 


 .share-button:hover {
     /* color: #0077b5; */
     color: white;
     background-color: #0077b5;

 }

 

 @media (max-width: 1400px) {
     .share-container {
         gap: 0.5rem;
         width: 100%;
         margin-left: 3rem;
         
     }

     .share-button {
         width: 30px;
         height: 30px;
     }
 }
 @media (max-width: 468px) {
    

    .share-container{
        margin-left: 100px;
       
    }
}