.privacy{
  margin-left: 7%;
  padding-top: 30px;
}
.privacysub{
  font-size: 1.1rem;
  font-family: system-ui;
  font-weight: 550;
  margin-top: 20px;
  margin-bottom: 20px;
}
/* Privacy.css */

/* Add styles for small screens (e.g., smartphones) */
@media only screen and (max-width: 767px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .row-margin {
    margin-left: 0;
    margin-right: 0;
  }
  .termspara {
    font-size: 14px;
  }
}

/* Add styles for medium screens (e.g., tablets) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* Add styles for large screens (e.g., desktops) */
@media only screen and (min-width: 992px) {
  .container {
    padding-left: 80px;
    padding-right: 170px;
  }
}
