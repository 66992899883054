.container-fluid {
    margin: 0 !important;
    z-index: 999;
    padding: 0 4rem !important;
}

#navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 80px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    transition: all 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#navbar-container.nav-background {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 16px rgba(31, 38, 135, 0.07);
}

.navbar {
    position: relative;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}

.navbar img {
    height: 40px;
    width: auto;
    transition: transform 0.3s ease;
    margin-top: 5px;
}

.nav-background .navbar img {
    /* filter: brightness(0) invert(1); */
}

.navbar img:hover {
    transform: scale(1.05);
}

.nav-data {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.navbar-nav {
    height: 100%;
    display: flex;
    gap: 2rem;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-direction: row;
    align-items: center;
}

.nav-item {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.menu-links {
    height: 100%;
    display: flex;
    align-items: center;
    color: black !important;
    text-decoration: none;
    font-weight: 700 !important;
    font-size: 1rem;
    letter-spacing: 0.5px;
    padding: 0.4rem 0.8rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    position: relative;
}

.menu-links::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background: #8B4513;
    transform: translateX(-50%);
    transition: width 0.3s ease;
}

.menu-links:hover {
    color: #8B4513 !important;
    background: rgba(139, 69, 19, 0.1);
    text-decoration: none;
}

.nav-background .menu-links {
    color: black !important;
}

.nav-background .menu-links:hover {
    color: #8B4513 !important;
    background: rgba(139, 69, 19, 0.1);
}

.social-media-bar {
    position: fixed;
    right: 0;
    top: 50vh;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    padding: 1.5rem 0.8rem;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 25px 0 0 25px;
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
    z-index: 1002;
    box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.05);
}

.social-media-bar a {
    color: #000000;
    transition: all 0.3s ease;
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 2.5rem;
    height: 2.5rem;
    margin: 0;
}

.nav-background .social-media-bar {
    background: rgba(139, 69, 19, 0.08);
}

.nav-background .social-media-bar a {
    color: #000000;
    background: rgba(255, 255, 255, 0.3);
}

.social-media-bar a:hover {
    color: #8B4513;
    transform: translateX(-3px) scale(1.1);
    text-shadow: 0 10px 20px rgba(139, 69, 19, 0.2);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 12px rgba(139, 69, 19, 0.15);
}

/* Specific social media icon colors on hover */
.social-media-bar .youtube.social:hover {
    color: #FF0000;
}

.social-media-bar .instagram.social:hover {
    color: #E4405F;
}

.social-media-bar .twitter.social:hover {
    color: #1DA1F2;
}

.social-media-bar .facebook.social:hover {
    color: #1877F2;
}

.social-media-bar .Linkedin.social:hover {
    color: #0A66C2;
}

.social-media-bar .whatsapp.social:hover {
    color: #25D366;
}

.social-media-bar .google.social:hover {
    color: #DB4437;
}

/* WhatsApp popup styles */
.whatsapp-popup {
    display: none;
    position: absolute;
    background: white;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 10px;
    white-space: nowrap;
    font-size: 0.9rem;
    z-index: 1002;
}

.whatsapp-popup.show {
    display: block;
    animation: fadeIn 0.3s ease;
}

.ok, .ok-2, .ok-3, .ok-5 {
    color: black !important;
    text-decoration: none;
    font-weight: 700 !important;
    font-size: 1rem;
    letter-spacing: 0.5px;
    padding: 0.4rem 0.8rem;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.dropdown-menu a, .dropdown-menu span {
    color: black !important;
    font-weight: 700;
    font-size: 0.95rem;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
    display: block;
    text-decoration: none;
}

.dropdown-menu a:hover, .dropdown-menu span:hover {
    color: #8B4513 !important;
    background: rgba(139, 69, 19, 0.1);
}

/* Hide dropdown by default */
.dropdown-menu {
    display: none !important;
}

.nav-item:hover .dropdown-menu {
    display: none !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Hide the vertical navigation that appears on the right */
.sidebar {
    display: none !important;
}

/* Only show sidebar when explicitly opened */
.sidebar.open {
    display: flex !important;
}

/* Mobile menu styles */
@media screen and (max-width: 1024px) {
    #navbar-container {
        padding: 0 2rem;
    }

    .navbar {
        padding: 0.5rem 1rem !important;
    }

    .nav-data {
        display: none;
    }

    .hamburger {
        display: block !important;
    }

    .hamburger .ver-line {
        display: block !important;
    }

    /* Hide social media bar on mobile */
    .social-media-bar {
        display: none;
    }

    .sidebar {
        display: block !important;
        position: fixed;
        top: 0;
        right: -100%;
        height: 100vh;
        width: 300px;
        background: white;
        padding: 80px 2rem 2rem;
        transition: right 0.3s ease;
        overflow-y: auto;
        box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
        z-index: 1003;
    }

    .sidebar.open {
        right: 0;
    }

    .sidebar .menu-links {
        color: #333 !important;
        padding: 1rem 0;
        font-size: 1.1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .sidebar .menu-links:hover {
        color: #8B4513 !important;
        background: none;
        padding-left: 10px;
    }
}

/* Smaller Mobile Screens */
@media screen and (max-width: 768px) {
    #navbar-container {
        padding: 0 1rem;
        height: 70px;
    }

    .navbar {
        padding: 0.5rem !important;
    }

    .navbar-logo {
        height: 35px;
        width: auto;
    }

    .hamburger {
        right: 10px;
        width: 44px;
        height: 44px;
    }

    .sidebar {
        width: 100%;
        padding: 70px 1.5rem 2rem;
    }
}

@media screen and (min-width: 1025px) {
    .sidebar, 
    .toogle-btn {
        display: none !important;
    }

    .navbar-nav {
        display: flex !important;
    }
}

/* Ensure social media bar stays visible but adjusts position on mobile */
@media screen and (max-width: 768px) {
    .navbar {
        padding: 0.5rem 1rem !important;
    }

    .sidebar {
        width: 100%;
        padding: 80px 1.5rem 2rem;
    }
}

/* Social Media Sidebar Styles */
.social-media-sidebar {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 50;
}

/* Hover Effects */
.social-icon-wrapper {
    position: relative;
    transition: all 0.3s ease;
}

.social-icon-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    border-radius: 9999px 0 0 9999px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.social-icon-wrapper:hover::before {
    opacity: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
    .social-media-sidebar {
        gap: 0.5rem;
    }
    
    .social-icon-wrapper {
        transform: scale(0.9);
    }
}

.navbar-logo {
    height: 45px;
    width: auto;
    margin: 0;
    object-fit: contain;
}

.menu-links {
    display: flex;
    align-items: center;
    height: 100%;
    color: #333;
    text-decoration: none;
    font-weight: 500;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
}

.nav-item {
    display: flex;
    align-items: center;
}

.navbar {
    display: flex;
    align-items: center;
}

.hamburger {
    display: none;
    cursor: pointer;
    padding: 12px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1004;
    background: transparent;
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.hamburger:hover {
    background-color: rgba(139, 69, 19, 0.1);
}

.hamburger .ver-line {
    display: block;
    width: 24px;
    height: 2px;
    background-color: #333;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    border-radius: 2px;
}

/* Hamburger Animation */
.hamburger.open .ver-line:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
    background-color: #8B4513;
}

.hamburger.open .ver-line:nth-child(2) {
    opacity: 0;
    transform: translateX(-20px);
}

.hamburger.open .ver-line:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
    background-color: #8B4513;
}

/* Hover effects for hamburger lines */
.hamburger:hover .ver-line {
    background-color: #8B4513;
}

@media screen and (max-width: 1024px) {
    .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.sidebar-social {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
}

.sidebar-social h6 {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    color: #333;
    font-weight: 600;
}

.social-icons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding: 0 1rem;
}

.social-icons a {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.05);
    color: #333;
    transition: all 0.3s ease;
    margin: 0 auto;
}

/* Social media icon specific hover colors */
.social-icons .youtube:hover {
    background: #FF0000;
    color: white;
}

.social-icons .instagram:hover {
    background: #E4405F;
    color: white;
}

.social-icons .twitter:hover {
    background: #1DA1F2;
    color: white;
}

.social-icons .facebook:hover {
    background: #1877F2;
    color: white;
}

.social-icons .Linkedin:hover {
    background: #0A66C2;
    color: white;
}

.social-icons .google:hover {
    background: #DB4437;
    color: white;
}

.social-icons .whatsapp:hover {
    background: #25D366;
    color: white;
}

.mobile-menu {
    display: none;
    position: relative;
    z-index: 1004;
}

@media screen and (max-width: 1024px) {
    .mobile-menu {
        display: block;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }

    .hamburger {
        position: relative;
        right: 0;
        top: 0;
        transform: none;
    }
}

@media screen and (max-width: 768px) {
    .mobile-menu {
        right: 0.5rem;
    }
}

.sidebar-dropdown {
    display: none;
    padding-left: 1rem;
    background: rgba(139, 69, 19, 0.03);
    border-radius: 8px;
    margin-top: 0.5rem;
}

.sidebar-dropdown.active {
    display: block;
    animation: slideDown 0.3s ease-out;
}

.sidebar-dropdown a {
    display: block;
    padding: 0.8rem 1rem;
    color: #333;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    transition: all 0.3s ease;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.sidebar-dropdown a:last-child {
    border-bottom: none;
}

.sidebar-dropdown a:hover {
    color: #8B4513;
    padding-left: 1.5rem;
    background: rgba(139, 69, 19, 0.05);
}

.menu-links.has-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-links.has-dropdown::after {
    content: '▼';
    font-size: 0.8rem;
    transition: transform 0.3s ease;
}

.menu-links.has-dropdown.active::after {
    transform: rotate(180deg);
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.mobile-menu-button {
    display: none;
    z-index: 1001;
}

@media screen and (max-width: 768px) {
    .mobile-menu-button {
        display: block;
        position: fixed;
        top: 1rem;
        right: 1rem;
        padding: 0.5rem;
        background: transparent;
        border: none;
        cursor: pointer;
    }

    .sidebar {
        position: fixed;
        top: 0;
        right: -100%;
        width: 80%;
        height: 100vh;
        background: white;
        transition: right 0.3s ease-in-out;
        z-index: 1000;
        overflow-y: auto;
        padding-top: 4rem;
        box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    }

    .sidebar.open {
        right: 0;
    }

    .social-media-bar {
        z-index: 999;
    }
}

/* Add responsive padding for different screen sizes */
@media (max-width: 1200px) {
    .container-fluid {
        padding: 0 2rem !important;
    }
}

@media (max-width: 768px) {
    .container-fluid {
        padding: 0 1rem !important;
    }
}