.FAQ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4rem 0;
  background-color: #f8f9fa;
}

.FAQbody {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.section5heading {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #8B4513;
  font-family: "Roboto Slab", serif;
  font-weight: bold;
}

.accordion {
  width: 100%;
  margin: 0 auto;
}

.accordion-item {
  background: white;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(139, 69, 19, 0.1);
}

.accordion-title {
  width: 100%;
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto Slab", serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: #8B4513;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  position: relative;
  z-index: 3;
  transition: background-color 0.2s ease;
  border-radius: 8px;
  user-select: none;
}

.accordion-title:hover {
  background-color: rgba(139, 69, 19, 0.05);
}

.accordion-title:focus {
  outline: none;
  background-color: rgba(139, 69, 19, 0.08);
}

.accordion-title:focus-visible {
  box-shadow: 0 0 0 2px rgba(139, 69, 19, 0.3);
}

.accordion-title-text {
  flex: 1;
  margin-right: 1rem;
}

.accordion-icon {
  font-size: 1rem;
  color: #8B4513;
  transition: transform 0.3s ease;
}

.accordion-icon.rotate {
  transform: rotate(180deg);
}

.accordion-error {
  color: #e53e3e;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #feb2b2;
  border-radius: 8px;
  background-color: #fff5f5;
}

.accordion-empty {
  color: #718096;
  padding: 1rem;
  text-align: center;
  border: 1px dashed #CBD5E0;
  border-radius: 8px;
}

.accordion-content {
  overflow: hidden;
  transition: all 0.3s ease-out;
  background-color: white;
  position: relative;
  z-index: 2;
}

.accordion-content-inner {
  padding: 0 1.25rem 1.25rem;
  color: #4A5568;
  font-size: 1rem;
  line-height: 1.6;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: white;
}

.accordion-content.active .accordion-content-inner {
  opacity: 1;
  transform: translateY(0);
}

/* Debug outline for development */
.debug .accordion-content {
  outline: 1px solid red;
}

.debug .accordion-content-inner {
  outline: 1px solid blue;
}

@media (max-width: 768px) {
  .accordion-title {
    font-size: 1rem;
    padding: 1rem;
  }

  .accordion-content-inner {
    padding: 0 1rem 1rem;
    font-size: 0.95rem;
  }
}

.accordion-toggle {
  float: left;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}